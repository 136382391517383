import React, { useEffect, useState } from 'react';
import DataTable, { DataTableColumn } from '../Components/DataTable';
import { ExtFixtureItem } from './FixtureOverviewModels';
import CreateRoundUtil from '../CreateRound/CreateRoundUtil';
import RoundOverviewUtil from '../RoundOverview/RoundOverviewUtil';
import { green, yellow } from '@material-ui/core/colors';
import { Box, Button, Link } from '@material-ui/core';
import { Link as RouterLink } from "react-router-dom";

interface ExtFixtureTableProps {
    items: ExtFixtureItem[];
    onSelect? : (item : ExtFixtureItem) => void;
    onMap? : (item : ExtFixtureItem) => void;
}

const ExtFixtureTable: React.FC<ExtFixtureTableProps> = (props) => {
    const [selectedId, setSelectedId] = useState<string>("");
    const [columns, setColumns] = useState<DataTableColumn<ExtFixtureItem>[]>([]);
 
    useEffect(() => {
      refreshColumns();
    }, []);
    
    const refreshColumns = () => {
      var cols: DataTableColumn<ExtFixtureItem>[] = [
        { id: 'id', numeric: false, disablePadding: true, label: "Ext Id", filterEnabled: true },
        { id: 'name', numeric: false, disablePadding: false, label: 'Ext name', filterEnabled: true },
        { id: 'startingAt', numeric: false, disablePadding: false, label: 'Ext Starting At', filterEnabled: true, renderValue: it => CreateRoundUtil.toGameDateString(it.startingAt) },
        { id: 'status', numeric: false, disablePadding: false, label: 'Ext Status', filterEnabled: true, renderValue: it => renderExtStatus(it) },
        { id: 'mappingId', numeric: false, disablePadding: false, label: 'Mapping Id', filterEnabled: true },
        { id: 'mappingStatus', numeric: false, disablePadding: false, label: 'Mapping Status', renderValue: it => renderMappingStatus(it) },
        { id: 'mappingReversed', numeric: false, disablePadding: false, label: 'Reversed' },
        { id: 'fixtureId', numeric: false, disablePadding: false, label: 'Fixture Id', filterEnabled: true },
        { id: 'fixtureName', numeric: false, disablePadding: false, label: 'Fixture Name' },
        { id: 'fixtureStartingAt', numeric: false, disablePadding: false, label: 'Fixture Starting at', renderValue: it => CreateRoundUtil.toGameDateString(it.startingAt) },
        { id: 'leagueTitle', numeric: false, disablePadding: false, label: 'League' },
        { id: 'fixtureStatus', numeric: false, disablePadding: false, label: 'Fixture Status', filterEnabled: true },
       ];
      setColumns(cols);
    }
    const renderExtStatus = (item : ExtFixtureItem) : string => {
      var statusName = item.status;
      if (item.extStatus && item.extStatus.length > 0) {
        statusName += ` (${item.extStatus})`;
      }
      return statusName;
    };
    const renderMappingStatus = (item : ExtFixtureItem) : any => {
      var statusName = item.mappingStatus;
      var color : string | undefined = undefined;
      if (item.mappingId && item.mappingId > 0) {
        color = RoundOverviewUtil.VALID_COLOR;
      } else if (statusName) {
        switch (statusName) {
          case "EXACT_MATCH":
            color = yellow[500];
            break;
          default:
            color = RoundOverviewUtil.INVALID_COLOR;
            break;
        }
      }
      if (color) {
        var content : any = null;
        if (item.fixtureId && props.onMap) {
          content = (<Link component={Button} onClick={e => onMap(item)}>{statusName}</Link>);
        } else {
          content = statusName;
        }
        return (
          <Box bgcolor={color}>{content}</Box>
        );
      }
      return statusName;
    };
    const renderReversed = (item : ExtFixtureItem) : any => {
      if (item.mappingReversed) {
        return (
          <Box bgcolor={RoundOverviewUtil.INVALID_COLOR}>X</Box>
        );
      } else if (item.mappingReversed === undefined) {
        return null;
      }
      return "-";
    };
    const onSelect = (item : ExtFixtureItem) => {
        setSelectedId(item.id);
        if (props.onSelect){
          props.onSelect(item);
        }
    };
    const onMap = (item : ExtFixtureItem) => {
      if (props.onMap){
        props.onMap(item);
      }
    };

    return (
      <DataTable columns={columns} items={props.items} getId={it => it.id} orderBy='startingAt' order='asc' resultFilterEnabled={true} size='xsmall' onSelect={onSelect}  />
    );
  };
  
  export default ExtFixtureTable;
 