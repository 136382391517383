import React, { ChangeEvent, useState } from "react";
import { Button, TextField, Box, MenuItem, Typography } from '@material-ui/core';
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import { Team } from "../RoundOverview/RoundOverviewModels";
import AgentUtil from "../AgentOverview/AgentUtil";
import RoundOverviewUtil from "../RoundOverview/RoundOverviewUtil";
import { imageFileTypes, renderImgFromArray } from "../common/helpers/ImageHelpers";

interface ImagePickerProps {
    onChange: (info: FileUploadInfo) => void;
}

export interface FileUploadInfo {
    fileName: string;
    fileType: string;
    file?: File;
    fileContent?: Uint8Array<ArrayBuffer>;
    byteCount: number;
    valid: boolean;
    message: string;
}

export interface ImagePickerResult {
  fileName: string;
  fileType: string;
  file?: File;
  fileContent?: Uint8Array<ArrayBuffer>;
  byteCount: number;
}
interface ImagePickerState {
    fileName: "";
    info?: FileUploadInfo;
  }

  function checkInfo(info: FileUploadInfo) : void {
    info.valid = false;
    info.message = "Not checked";
    if (!info.fileName || info.fileName.length <= 0) {
        info.message = "Filename not specified";
        return;
    }
    if (!info.fileType || info.fileType.length <= 0) {
        info.message = "File type not specified";
        return;
    }
    if (!imageFileTypes.includes(info.fileType)) {
        info.message = `File type not image. Must be one of: [${imageFileTypes.join(", ")}]` ;
        return;
    }
    if (!info.file) {
      info.message = "File not found";
      return;
    }
    if (!info.fileContent) {
        info.message = "File content not read";
        return;
    }
    info.valid = true;
    info.message = "";
  }
  
const ImagePicker: React.FC<ImagePickerProps> = (props) => {
    // const getDefaultFormState = () : ImagePickerState => {
    //     return {
    //         fileName: "",
    //     };
    // }
    
    // const [formState, setFormState] = useState<ImagePickerState>(getDefaultFormState());
    const [file, setFile] = useState<File | undefined>(undefined);
    const [info, setInfo] = useState<FileUploadInfo>({
      fileName: "",
      fileType: "",
      byteCount: 0,
      valid: false,
      message: "Required"
    });

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        var fileType = "";
        var infoX : FileUploadInfo = {
          fileName: e.target.value,
          fileType: "",
          byteCount: 0,
          valid: false,
          message: "Required"
        };
        if (infoX.fileName) {
            const pos = infoX.fileName.lastIndexOf(".");
            if (pos >= 0) {
                infoX.fileType = infoX.fileName.substring(pos + 1).toLowerCase();
                if (imageFileTypes.includes(infoX.fileType)) {
                    if (e.target.files) {
                        infoX.file = e.target.files[0];
                        if (infoX.file) {
                          let fileReader = new FileReader();
                          fileReader.onload = function () {
                            handleFileResult(infoX, fileReader.result, fileReader.error);
                          };
                          fileReader.readAsArrayBuffer(infoX.file)
                          return;
                        }
                      }              
                }
            }
        }


        checkInfo(infoX);
        setInfo(infoX);
        props.onChange(infoX);
      };
      const handleFileResult = (infoX: FileUploadInfo, fileContent: string | ArrayBuffer | null, error: DOMException | null) => {
        try {
            handleFileResultBody(infoX, fileContent, error);
            props.onChange(infoX);
        } catch (error) {
            infoX.valid = false;
            infoX.message = "Handle file result error: " + error;
        } finally {
            setInfo(infoX);
        }
      }
      const handleFileResultBody = (infoX: FileUploadInfo, fileContent: string | ArrayBuffer | null, error: DOMException | null) => {
        infoX.valid = false;
        infoX.message = "";
        infoX.byteCount = 0;
    
        if (error) {
          infoX.message = `Read file error: ${error.message}`;
          return;
        } 
        if (!fileContent) {
          infoX.message = "No file content";
          return;
        }
        //var sFileContent : string = "";
        // if (typeof(fileContent) !== 'string') {
        //   infoX.message = `File content datatype is not string '${typeof(fileContent)}'`;
        //   return;
        // }
        if (!(fileContent instanceof ArrayBuffer)) {
            infoX.message = `File content datatype is not ArrayBuffer '${typeof(fileContent)}'`;
            return;
        }
        var array = new Uint8Array(fileContent);
        var rowCount = array.byteLength;
        infoX.fileContent  = array;
        infoX.valid = true;
        infoX.byteCount = rowCount;
    };

    var img = null;
    var imgInfoBox = null;
    if (info.valid && info.fileContent) {
        // var decoder = new TextDecoder('utf8');
        // var base64 = btoa(decoder.decode(info.fileContent));
        var array = Array.from(info.fileContent);
        //img = renderImgFromArray(array, info.fileType);
        ////var array = [].slice.call(uint8Array);
        var binaryString = String.fromCharCode.apply(null, array);
        var base64 = btoa(binaryString);
        img = (
            <img src={`data:image/${info.fileType};base64,${base64}`}></img>
        );
        var imgInfo = `array.length: ${array.length} | binaryString.length: ${binaryString.length} | base64.length: ${base64.length}`;
        imgInfoBox = (
          <Box>{imgInfo}</Box>
        );
    }
    return (
        <div>
      <Box>
        <TextField 
          type="file"
          label="File"
          // value={info.fileName} 
          onChange={handleFileChange} />
      </Box>

        <Box>
            <h3>File content</h3>
            <Typography>File: {info.fileName}</Typography>
            <Typography>File type: {info.fileType}</Typography>
            <Typography >Size: {info.byteCount}</Typography>
            <Typography color={info.valid ? undefined : "error"}>Valid: {info.valid ? "yes" : "no"}</Typography>
            <Typography color={info.valid ? undefined : "error"}>Message: {info.message}</Typography>
            <Typography>Image:</Typography>
            {img}
            {imgInfoBox}
        </Box>
        </div>
    );
};

export default ImagePicker;
