import React, { useEffect, useState } from "react";
import { Box, Button, MenuItem, TextField } from "@material-ui/core";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { ExtLeague, ExtLeagueMapping, MapLeagueRequest } from "./LeagueModels";
import LeagueUtil from "./LeagueUtil";
import ExtLeagueTable from "./ExtLeagueTable";
import { bool } from "prop-types";
import { BaseItemState } from "../Ticker2/Ticker2Models";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import { act } from "react-dom/test-utils";
import RoundOverviewUtil from "../RoundOverview/RoundOverviewUtil";
import FixtureOverviewUtil from "../FixtureOverview/FixtureOverviewUtil";
import { ActionState } from "../FixtureOverview/FixtureOverviewModels";
import { UpdateItemState } from "../CreateSyndicate/CreateSyndicateModels";
import { blue } from "@material-ui/core/colors";
import ConfirmationDialog from "../Components/ConfirmationDialog";

interface ExtLeagueViewState {
    // leagueId: number;
    // leagueName: string;
    providerId: string;
    valid: boolean;
    providerIdError: string;
    // extLeagues: ExtLeague[];
    // refreshing: boolean;
}
interface ExtLeagueItemState extends BaseItemState<ExtLeague, string> {
    leagueId: number;
}
interface MapLeagueState extends UpdateItemState<ExtLeague> {
    leagueId: number;
    leagueName: string;
}
interface ExtLeagueViewProps {
    leagueId: number;
    leagueName: string;
}
const ExtLeagueView: React.FC<ExtLeagueViewProps> = (props) => {
    const [formState, setFormState] = useState<ExtLeagueViewState>({
        // leagueId: 0,
        // leagueName: "",
        providerId: "test",
        valid: true,
        providerIdError: "",
        // extLeagues: [],
        // refreshing: true
    });
    const [itemState, setItemState] = useState<ExtLeagueItemState>({
        leagueId: props.leagueId,
        items: [],
        more: false,
        lastId: "",
        refreshing: false

    });
    const [selectedItem, setSelectedItem] = useState<ExtLeague | undefined>(undefined);
    // const [actionError, setActionError] = useState<string>("");
    const [actionState, setActionState] = useState<ActionState>(FixtureOverviewUtil.createEmptyActionState());
    const [mapLeagueState, setMapLeagueState] = useState<MapLeagueState>({
        method: 'none',
        item: undefined,
        handled: false,
        updateNo: 0,
        version: 0,
        leagueId: 0,
        leagueName: ""
    });
    const history = useHistory();   

    useEffect(() => {
        refreshItems("Init");
    }, []);

    const refreshItems = (caller: string) => {
        if (itemState.refreshing) {
            return;
        }
        if (!formState.valid) {
            return;
        }
        var rs : ExtLeagueItemState = {
            leagueId: props.leagueId,
            items: [],
            more: false,
            lastId: "",
            refreshing: false
        }
        var ast = FixtureOverviewUtil.createEmptyActionState();
        var lastId = caller === "onMore" ? itemState.lastId : "";
        if (lastId.length > 0) {
            rs.items = itemState.items;
        }
        var success = false;
        itemState.refreshing = true;
        const sportId = 2;
        setActionState(FixtureOverviewUtil.createActionState(true, false, `RefreshItems(${caller})...`));
        LeagueUtil.getExtLeagues(formState.providerId, sportId).then( resp => {
            if (!resp.success) {
                ast.error = true;
                ast.message = `RefreshItems(${caller}) error: ${resp.message}`;
                return;
            }
            var listResponse = resp.item;
            if (listResponse) {
                if (listResponse.items) {
                    if (lastId.length > 0) {
                        for (const item of listResponse.items) {
                            rs.items.push(item);
                        }
                    } else {
                        rs.items = listResponse.items;
                    }
                    if (listResponse.items.length > 0) {
                        rs.lastId = listResponse.items[listResponse.items.length - 1].id;
                    }
                    // if (listResponse.pageSize <= listResponse.items.length) {
                    //     rs.more = true;
                    // }
                }           
            }
            success = true;
        }).catch( error => {
            ast.error = true;
            ast.message = CreateRoundUtil.getExceptionMessage(error, false, `RefreshItems(${caller}) error`);
        }).finally( () => {
            if (success || lastId.length <= 0) {
                setItemState(rs);
            }
            itemState.refreshing = false;
            setActionState(ast);
        });

        // var fs : ExtLeagueViewState = {
        //     leagueId: props.leagueId,
        //     leagueName: props.leagueName,
        //     providerId: formState.providerId,
        //     valid: formState.valid,
        //     providerIdError: formState.providerIdError,
        //     extLeagues:[],
        //     refreshing: false
        // }
        // var pageSize = 100;
        
        // var items: ExtLeague[] = [];
        // var sportId = 2; //Football
        // formState.refreshing = true;
        // setActionError("");
        // LeagueUtil.getExtLeagues(fs.providerId, sportId).then((resp) => {
        //     if (!resp.success) {
        //         setActionError(`${caller} error: ${resp.message}`);
        //     } else {
        //         if (resp.item?.items) {
        //             items = resp.item.items;
        //         }
        //     }
        // }).catch(error => {
        //     setActionError(CreateRoundUtil.getExceptionMessage(error, false, `${caller} error`));
        // }).finally(() => {
        //     formState.refreshing = false;
        //     fs.extLeagues = items;
        //     setFormState(fs);
        // });
    };
    const onRefreshExtLeagues = () => {
        refreshItems("Refresh");
    };
    const unmapLeague = (item: ExtLeague) => {
        if (item.mappingId === undefined || item.mappingId <= 0) {
            setActionState(FixtureOverviewUtil.createActionState(true, false, `Unmap league failure: Ext league '${item.name}' is not mapped.`));
            return;
        }
        var ast = FixtureOverviewUtil.createEmptyActionState(); 
        setActionState(FixtureOverviewUtil.createActionState(true, false, "Unmapping league..."));
        var finished = false;
        LeagueUtil.unmapLeague(item.mappingId).then((resp) => {
            if (!resp.success) {
                ast.error = true;
                ast.message = `Unmap League error: ${resp.message}`;
                return;
            }
            var extMapping : ExtLeagueMapping = {...item.mapping, id: 0 };
            var extLeague = {... item, mapping: extMapping};
            extLeague.mappingId = 0;
            extLeague.mappingStatus = "UNMAPPED";
            var extLeagueIndex = itemState.items.findIndex(it => it.id === item.id);
            if (extLeagueIndex >= 0) {
                itemState.items[extLeagueIndex] = extLeague;
                setSelectedItem(extLeague);
            }
        }).catch(error => {
            ast.error = true;
            ast.message = CreateRoundUtil.getExceptionMessage(error, false, "Unmap League error");
        }).finally(() => {
            setActionState(ast);
        });
    };

    const onMap = () => {
        if (props.leagueId <= 0) {
            setActionState(FixtureOverviewUtil.createActionState(false, true, "League is not selected."));
            return;
        }
        if (!selectedItem) {
            setActionState(FixtureOverviewUtil.createActionState(false, true, "Ext league is not selected."));
            return;
        }
        if (selectedItem.mappingId && selectedItem.mappingId > 0) {
            setActionState(FixtureOverviewUtil.createActionState(false, true, "Ext league is already mapped."));
            return;
        }
        var request : MapLeagueRequest = {
            providerId: formState.providerId,
            leagueId: props.leagueId,
            extCompetitionId: selectedItem.id,
            extCompetitionName: selectedItem.name
        };
        var ast = FixtureOverviewUtil.createEmptyActionState();
        
        var sportId = 2; //Football
        setActionState(FixtureOverviewUtil.createActionState(true, false, `Mapping league...`));
        //formState.refreshing = true;
        LeagueUtil.mapExtLeague(request).then((resp) => {
            if (!resp.success) {
                ast.error = true;
                ast.message = `Unmap League error: ${resp.message}`;
                return;
            }
            if (!resp.item) {
                return;
            }
            var item = resp.item;
            selectedItem.mapping = { 
                id: item.id,
                leagueId: item.leagueId,
                leagueName: item.leagueName
            };
            selectedItem.mappingId = item.id;
            selectedItem.leagueId = item.leagueId;
            selectedItem.leagueName = item.leagueName;
            selectedItem.mappingStatus = "REGISTERED";
            var extLeagueIndex = itemState.items.findIndex(it => it.id === selectedItem.id);
            if (extLeagueIndex >= 0) {
                var ist = {...itemState, refreshing: false };
                ist.items[extLeagueIndex] = selectedItem;
                setItemState(ist);
            }
        }).catch(error => {
            ast.error = true;
            ast.message = CreateRoundUtil.getExceptionMessage(error, false, "Map League error");
        }).finally(() => {
            setActionState(ast);
        });
    };
    const onMapItem = (item: ExtLeague) => {
        if (!item) {
            setActionState(FixtureOverviewUtil.createActionState(false, true, "Ext league is not selected."));
            return;
        }
        if (!item.leagueId) {
            setActionState(FixtureOverviewUtil.createActionState(false, true, "Ext league is not mapped to league."));
            return;
        }
        var mapping = item.mapping;
        if (!mapping) {
            setActionState(FixtureOverviewUtil.createActionState(false, true, "Ext league is not mapped to league."));
            return;

        }
        // if (item.mappingId && item.mappingId > 0) {
        //     setAction(FixtureOverviewUtil.createActionState(false, true, "Ext fixture is already mapped."));
        //     return;
        // }
        setMapLeagueState({
            method: (item.mappingId && item.mappingId > 0) ? 'remove' : 'add',
            item: item,
            handled: false,
            updateNo: 0,
            version: 0,
            leagueId: 0,
            leagueName: ""
        });

    };
    const checkState = (fs: ExtLeagueViewState) => {
        var valid = true;
        var providerIdErr = "";
        if (!fs.providerId || fs.providerId.length <= 0) {
         valid = false;
         providerIdErr = "Required";
        }
        fs.valid = valid;
        fs.providerIdError = providerIdErr;
     };
     const onChangeProviderId = (providerId: string) => {
       if (providerId === formState.providerId) {
        return;
       }
       var fs = {...formState, providerId: providerId};
       var ist : ExtLeagueItemState = {
            leagueId: props.leagueId,
            items: [],
            more: false,
            lastId: "",
            refreshing: false
        }
        checkState(fs);
        setFormState(fs);
        setItemState(ist);
        setSelectedItem(undefined);

    };
    const onSelect = (item : ExtLeague) => {
        setSelectedItem(item);
    };
    const onUnmapLeagueClose = (confirmed: boolean | undefined) : void => {
        if (mapLeagueState.handled) {
            return;
        }
        var ms = {...mapLeagueState, handled: true};
        setMapLeagueState(ms);
        if (!confirmed || !ms.item) {
            return;
        }
        unmapLeague(ms.item);      
    };
    
    var isExecuting = itemState.refreshing || actionState.executing;
    var extLeagues = itemState.items;
    if (itemState.leagueId !== props.leagueId){
        itemState.leagueId = props.leagueId;
        itemState.items = [];
        refreshItems("ChangeLeague");
    }
    // var leagueInfo = "League - Not selected";
    // if (props.leagueId > 0) {
    //     leagueInfo = `League - Id:${props.leagueId} | Name: ${props.leagueName}`;
    // }
    var extLeagueInfo = "Ext league - Not selected";
    if (selectedItem) {
        extLeagueInfo = `Ext league - Id:${selectedItem.id} | Name: ${selectedItem.name} | Country: ${selectedItem.countryName} | Mapping Id: ${selectedItem.mappingId ? selectedItem.mappingId.toString() : ""}`;
    }
    var actionBox = null;
    if ((actionState.executing || actionState.error) && actionState.message.length > 0) {
        actionBox = (
            <Box color={actionState.error ? RoundOverviewUtil.INVALID_COLOR : blue[500]} fontSize={12}>
                {actionState.message}
            </Box>
        );
    }
    var mapEnabled = (props.leagueId > 0 && selectedItem && !selectedItem.mapping);
    var providerIds = LeagueUtil.getProviderIds();

    var mapDialog = null;
    if (mapLeagueState.item && !mapLeagueState.handled) {
        if (mapLeagueState.method === "remove") {
            var mapItem = mapLeagueState.item;
            const unmapContent = (
                <Box>
                    Please confirm that you want to unmap league <br/>'<b>{mapItem.leagueName}</b>' <br/>with external<br/>
                    '<b>{mapItem.name}</b>'<br/>at provider<br/><b>{formState.providerId}</b><br/>with mapping id<br/><b>{mapItem.mappingId}</b> .
                </Box>
            );
            mapDialog = (
                <ConfirmationDialog open={true} title="Unmap league" message="Please confirm that you want to unmap league." messageElement={unmapContent} onClose={onUnmapLeagueClose} />
            );            
        }
    }

    return (
        <div>
            {/* <Box fontSize={10}>
                {leagueInfo}
            </Box> */}
            <Box fontSize={10} marginTop={2}>
                <TextField
                    label="Provider"
                    select
                    name="providerId"
                    value={formState.providerId}
                    onChange={e => onChangeProviderId(e.target.value)}
                    style={{width:400, marginRight:"5px"}}
                    error={formState.providerIdError.length > 0}
                    helperText={formState.providerIdError}
                >
                    {providerIds.map(providerId => (
                    <MenuItem key={`providerId_${providerId}`} value={providerId}>
                        {providerId}
                    </MenuItem>
                    ))}
                </TextField>
                <Button 
                    variant="contained"
                    color="secondary"
                    size="small"
                    disabled={isExecuting}
                    style={{marginRight: "5px"}}
                    onClick={e => onRefreshExtLeagues()}>
                    Search
                </Button>
            </Box>
            <Box fontSize={10} marginTop={2}>
                {extLeagueInfo}
            </Box>
            <Box>
                <Button 
                    variant="contained"
                    color="secondary"
                    size="small"
                    disabled={isExecuting || !mapEnabled}
                    style={{marginRight: "5px"}}
                    onClick={e => onMap()}>
                    Map
                </Button>
            </Box>
            {actionBox}
            <ExtLeagueTable items={extLeagues} onSelect={onSelect} onMap={onMapItem} />
            {mapDialog}
            {/* <Box>
                <pre>{JSON.stringify(formState, undefined, 2)}</pre>
            </Box> */}
        </div>
    );
};

export default ExtLeagueView;

