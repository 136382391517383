import React from 'react';
export  const imageFileTypes : string[] = ["gif", "jpeg", "png"];

export function renderImgFromArrayBuffer(image: Uint8Array<ArrayBuffer>, imageType: string) : JSX.Element {
    var array = Array.from(image);
    ////var array = [].slice.call(uint8Array);
    return renderImgFromArray(array, imageType);
}

export function renderImgFromArray(image: number[], imageType: string) : JSX.Element {
    var binaryString = String.fromCharCode.apply(null, image);
    return renderImgFromBinaryString(binaryString, imageType);
}
export function renderImgFromBinaryString(image: string, imageType: string) : JSX.Element {
    var base64 = btoa(image);
    var img = (
        <img src={`data:image/${imageType};base64,${base64}`}></img>
    );
    return img;
}

export const fileToDataString = (file: File) : Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onerror = (error) => reject(error);
      reader.onload = () => resolve(reader.result as string);
    });
};