import React, { useEffect, useState } from "react";
import { Box, Button } from "@material-ui/core";
import { useHistory, Link as RouterLink } from "react-router-dom";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import { QueryParams } from "../models/CouponShare";
import FixtureTable from "../TeamOverview/FixtureTable";
import { Fixture, FixtureFilter } from "../TeamOverview/TeamOverviewModels";
import FixtureOverviewUtil from "../FixtureOverview/FixtureOverviewUtil";

interface LeagueOverviewFixturesState {
    leagueId: number;
    leagueName: string;
    fixtures: Fixture[];
    // q: string;
    // from: string;
    // to: string;
    // status: string;
}
interface LeagueOverviewFixturesProps {
    leagueId: number;
    leagueName: string;
}
const LeagueOverviewFixtures: React.FC<LeagueOverviewFixturesProps> = (props) => {
    const fromDays = -14;
    const toDays = 30;
    // const [roundId, setRoundId] = useState<number>(0);
    // const [fixtures, setFixtures] = useState<RoundOverviewFixture[]>([]);
    const [formState, setFormState] = useState<LeagueOverviewFixturesState>({
        leagueId: 0,
        leagueName: "",
        fixtures: [],
        // q: "",
        // from: CreateRoundUtil.toGameDateString(new Date()),
        // to: CreateRoundUtil.toGameDateString(CreateRoundUtil.addDays(new Date(), 7)),
        // status: ""
    });
    const history = useHistory();   

    useEffect(() => {
        refreshFixtures();

    }, []);

    const refreshFixtures = () => {
        var from = CreateRoundUtil.addDays(new Date(), fromDays);
        var to = CreateRoundUtil.addDays(new Date(), toDays);
        var fs : LeagueOverviewFixturesState = {
            leagueId: props.leagueId,
            leagueName: props.leagueName,
            fixtures:[],
        }
        if (props.leagueId <= 0) {
            setFormState(fs);
            return;
        }
        var pageSize = 100;
        // //var status : string | undefined = "PENDING";
        // var status : string | undefined = undefined;
        // var fixturePattern : string | undefined = undefined;
        // var params: QueryParams = {
        //     pagination: { page: 0, perPage: pageSize },
        //     filter: { status: status, fromAt: from, toAt: to, leagueId: props.leagueId.toString(), q: fixturePattern },
        //     sort: { field: "startingAt", order: "asc" }
        // };
        
        // var fixtures2: FixtureListItem[] = [];
        // CreateRoundUtil.getApiItems<Fixture>("fixtures", params, fixturePattern).then((resp) => {
        //     var fixtures1 : Fixture[] = [];
        //     if (!resp.success) {
        //         return;
        //     }
        //     if (!resp.item) {
        //         return;
        //     }
        //     fixtures1 = resp.item;
        //     fixtures2 = CreateRoundUtil.toFixtureListItems(fixtures1);
        // }).catch(error => {

        // }).finally(() => {
        //     fs.fixtures = fixtures2;
        //     setFormState(fs);
        // });
        //var status : string | undefined = "PENDING";
        
        var fixtures2: Fixture[] = [];
        var filter : FixtureFilter = { leagueId: props.leagueId};
        FixtureOverviewUtil.getFixtures(pageSize, from, to, 0, filter).then((resp) => {
            var fixtures1 : Fixture[] = [];
            if (!resp.success) {
                return;
            }
            if (!resp.item?.items) {
                return;
            }
            fixtures2 = resp.item.items;
        }).catch(error => {

        }).finally(() => {
            fs.fixtures = fixtures2;
            setFormState(fs);
        });
    };
    const onRefreshFixtures = () => {
        refreshFixtures();
    };
    var fixtures1 = formState.fixtures;
    if (formState.leagueId !== props.leagueId){
        formState.leagueId = props.leagueId;
        formState.fixtures = [];
        fixtures1 = [];
        refreshFixtures();
    }
    var dayRange = `League: ${props.leagueName} | 'Starting at' range is ${fromDays} to ${toDays} days from now`
    return (
        <div>
            <Box fontSize={10}>
                {dayRange}
            </Box>
            <FixtureTable items={fixtures1} />
        </div>
    );
};

export default LeagueOverviewFixtures;

