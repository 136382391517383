import AgentUtil from "../AgentOverview/AgentUtil";
import { GenericResponse } from "../CreateRound/CreateRoundModels";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import InvokeApiMethods from "../ExternalApi/ExternalApiModels";
import { BaseResponse } from "../FixtureOverview/FixtureOverviewModels";
import { ListResponse } from "../api/ApiNgModels";
import ApiNgUtil from "../api/ApiNgUtil";
import { QueryParams } from "../models/CouponShare";
import { ExtLeague, GetLeaguesRequest, League, LeagueMapping, LeagueMappingFilter, LeagueNameParts, LeagueStat, MapLeagueRequest } from "./LeagueModels";

class LeagueUtil {
    static TEST_PROVIDER = "test";
    static PROVIDER_IDS : string[] = [LeagueUtil.TEST_PROVIDER];

    static async getLeagues(params?: QueryParams) : Promise<GenericResponse<ListResponse<League>>> {
        var listResponse = await ApiNgUtil.getList<League>("leagues", params);
        if (!listResponse.success) {
            return listResponse;
        }
        var leagues : League[] = [] 
        if (listResponse.item?.items) {
            leagues = listResponse.item?.items;
        }
        if (leagues) {
            for (var i = 0; i < leagues.length; i++) {
                var league = leagues[i];
                LeagueUtil.fixLeague(league);
            }
        }
        return listResponse;
    }
    static async updateLeague(id: number, updateJson: string) : Promise<GenericResponse<League>> {
        var resource = `leagues/${id}`;
        var apiResponse = await ApiNgUtil.invokeApi2(resource, InvokeApiMethods.PATCH(), updateJson);
        if (!apiResponse.success) {
            return CreateRoundUtil.createGenericResponse<League>(false, apiResponse.message, null);
        }
        var league : League | null = null; 
        if (apiResponse.json) {
            league = apiResponse.json;
        }
        if (league) {
            LeagueUtil.fixLeague(league);
        }
        return CreateRoundUtil.createGenericResponse<League>(true, apiResponse.message, league);
    }
    static getLeagueNameParts(leagueName: string | undefined | null) : LeagueNameParts | undefined {
        if (!leagueName || leagueName.length <= 0) {
            return undefined;
        }
        var leagueNameWithoutLocation = leagueName;
        var leagueLocation = "";
        var leagueTitle = leagueName;
        var match = leagueName.match(CreateRoundUtil.leagueFormatPattern);
        if (match) {
            var leagueNameWithoutLocation = match.groups?.name ?? leagueName;
            leagueLocation = match.groups?.location ?? "";
            if (leagueNameWithoutLocation !== leagueName && leagueLocation.length > 0) {
                leagueTitle = leagueLocation + " - " + leagueNameWithoutLocation;
            }
        }

        return {
            name: leagueName,
            location: leagueLocation,
            nameWithoutLocation: leagueNameWithoutLocation,
            title: leagueTitle
        };
    }

    static fixLeague(league: League) {
        if (!league) {
            return;
        }
        var parts = LeagueUtil.getLeagueNameParts(league.name);
        if (!parts) {
            return;
        }
        league.title = parts.title;
        league.location = parts.location;
        league.nameWithoutLocation = parts.nameWithoutLocation;        

        // var leagueName: string = league.name;
        // var leagueLocation = "";
        // var leagueTitle = league.name;
        // var match = leagueName.match(CreateRoundUtil.leagueFormatPattern);
        // if (match) {
        //     leagueName = match.groups?.name ?? league.name;
        //     leagueLocation = match.groups?.location ?? "";
        //     if (leagueName !== league.name && leagueLocation.length > 0) {
        //         leagueTitle = leagueLocation + " - " + leagueName;
        //     }
        // }
        // league.title = leagueTitle;
        // league.location = leagueLocation;
        // league.nameWithoutLocation= leagueName;        
    }
    static fixLeagueProps(leagueMapping: LeagueMapping) {
        if (!leagueMapping) {
            return;
        }
        var parts = LeagueUtil.getLeagueNameParts(leagueMapping.leagueName);
        if (!parts) {
            return;
        }
        leagueMapping.leagueTitle = parts.title;
        leagueMapping.leagueLocation = parts.location;
        leagueMapping.leagueNameWithoutLocation = parts.nameWithoutLocation;        
        // var leagueName: string = leagueMapping.leagueName;
        // var leagueLocation = "";
        // var leagueTitle = leagueMapping.leagueName;
        // var match = leagueName.match(CreateRoundUtil.leagueFormatPattern);
        // if (match) {
        //     leagueName = match.groups?.name ?? leagueMapping.leagueName;
        //     leagueLocation = match.groups?.location ?? "";
        //     if (leagueName !== leagueMapping.leagueName && leagueLocation.length > 0) {
        //         leagueTitle = leagueLocation + " - " + leagueName;
        //     }
        // }
        // leagueMapping.leagueTitle = leagueTitle;
        // leagueMapping.leagueLocation = leagueLocation;
        // leagueMapping.leagueNameWithoutLocation= leagueName;        
    }
    static async getLeagueStat() : Promise<GenericResponse<LeagueStat>> {
        var response = await ApiNgUtil.getObject<LeagueStat>("leagues/stat");
        return response;
    }
    private static async getLeagueMappingsImpl(url: string) {
        var listResponse = await ApiNgUtil.getListByUrl<LeagueMapping>(url, "leagueMappings");
        if (!listResponse.success) {
            return listResponse;
        }
        var items : LeagueMapping[] = [] 
        if (listResponse.item?.items) {
            items = listResponse.item?.items;
        }
        if (items) {
            for (var i = 0; i < items.length; i++) {
                var item = items[i];
                LeagueUtil.fixLeagueProps(item);
            }
        }
        return listResponse;

    }
    static async getLeagueMappingsByLeagueId(leagueId: number) : Promise<GenericResponse<ListResponse<LeagueMapping>>> {
        var url = `leagues/${leagueId}/mappings`;
        var response = await LeagueUtil.getLeagueMappingsImpl(url);
        return response;
        // var listResponse = await ApiNgUtil.getListByUrl<LeagueMapping>(url, "leagueMappings");
        // if (!listResponse.success) {
        //     return listResponse;
        // }
        // var items : LeagueMapping[] = [] 
        // if (listResponse.item?.items) {
        //     items = listResponse.item?.items;
        // }
        // if (items) {
        //     for (var i = 0; i < items.length; i++) {
        //         var item = items[i];
        //         LeagueUtil.fixLeagueProps(item);
        //     }
        // }
        // return listResponse;
    }
    static async getLeagueMappings(pageSize: number, from: Date| undefined, to: Date| undefined, lastId: number, filter?: LeagueMappingFilter) : Promise<GenericResponse<ListResponse<LeagueMapping>>> {
        var urlParams = AgentUtil.getUrlParams(pageSize, from, to, lastId, filter);
        var url = `leagues/mappings?${urlParams}`;
        var response = await LeagueUtil.getLeagueMappingsImpl(url);
        return response;
    }
    static async getExtLeagues(providerId: string, sportId: number) : Promise<GenericResponse<ListResponse<ExtLeague>>> {
        var url = `extLeagues?providerId=${providerId}&sportId=${sportId}`;
        var listResponse = await ApiNgUtil.getListByUrl<ExtLeague>(url, "extLeagues");
        if (!listResponse.success) {
            return listResponse;
        }
        var items : ExtLeague[] = [] 
        if (listResponse.item?.items) {
            items = listResponse.item?.items;
        }
        if (items) {
            for (var i = 0; i < items.length; i++) {
                var item = items[i];
                if (item.mapping) {
                    item.mappingId = item.mapping.id;
                    item.leagueId = item.mapping.leagueId;
                    item.leagueName = item.mapping.leagueName;
                    if (item.mappingId && item.mappingId > 0) {
                        item.mappingStatus = "REGISTERED";
                    } else {
                        item.mappingStatus = "OTHER_MATCH";
                    }
                }
            }
        }
        return listResponse;
    }
    static async mapExtLeague(request: MapLeagueRequest) : Promise<GenericResponse<LeagueMapping>> {
        const METHOD_NAME = "LeagueUtil.mapExtLeague";
        var url = `leagues/${request.leagueId}/map`;
        var saveJson = JSON.stringify(request);
        try {
            var resp = await ApiNgUtil.getObjectWithBody<LeagueMapping>(url, InvokeApiMethods.POST(), saveJson);
            return resp;
        } catch (error) {
            console.error(METHOD_NAME + " error.", error);
            return CreateRoundUtil.createGenericResponse<LeagueMapping>(false, METHOD_NAME + " error: " + error, null);
        }
    }
    static async unmapLeague(mappingId: number) : Promise<GenericResponse<boolean>> {
        const METHOD_NAME = "LeagueUtil.unmapLeague";
        var url = `leagues/mappings/${mappingId}`;
        try {
            var resp = await ApiNgUtil.getObjectWithMethod<BaseResponse>(url, InvokeApiMethods.DELETE());
            if (resp.success) {
                if (resp.item) {
                    const success : boolean = (resp.item.success && resp.item.success === true);
                    return CreateRoundUtil.createGenericResponse(success, resp.item.message, success);
                } 
                return CreateRoundUtil.createGenericResponse(false, "No response", false);
            }
            return CreateRoundUtil.createGenericResponse(false, resp.message, false);
        } catch (error) {
            console.error(METHOD_NAME + " error.", error);
            return CreateRoundUtil.createGenericResponse<boolean>(false, METHOD_NAME + " error: " + error, false);
        }
    }
    static getProviderIds() : string[] {
        var providerIds : string[] = [];
        for (const providerId of LeagueUtil.PROVIDER_IDS) {
            providerIds.push(providerId);
        }
        return providerIds;
    }
}
export default LeagueUtil;