import React, { useEffect, useState } from "react";
import { useLocation, Link as RouterLink, useHistory } from 'react-router-dom';
import { Button, TextField, Box, MenuItem, Container, FormControlLabel, Switch } from '@material-ui/core';
import { Title } from "react-admin";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import AdminUtil from "../Admin/AdminUtil";
import { BaseItemState } from "../Ticker2/Ticker2Models";
import { Operator, OperatorSetting } from "../Admin/AdminModels";
import OperatorSelect from "../Components/OperatorSelect";
import AgentUtil from "./AgentUtil";
import { GenericResponse } from "../CreateRound/CreateRoundModels";
import { SyndicateOverviewItem, SyndicateUpdate } from "./AgentModels";
import RoundOverviewUtil from "../RoundOverview/RoundOverviewUtil";
import { formatToCurrency, formatToPercent } from "../common/helpers/CurrencyHelpers";

interface EditSyndicateState {
    updateNo: number;
    item?: SyndicateOverviewItem;
    id: number;
    name: string;
    status: string;
    valid: boolean;
    nameError: string;
    statusError: string;
    value?: SyndicateOverviewItem;
    update?: SyndicateUpdate;
}
function checkState(state : EditSyndicateState) {
    var valid = true;
    var nameErr = "";
    var statusErr = "";

    if (state.name.length === 0) {
        valid = false;
        nameErr = "Required";
    } else if (state.name.trim().length === 0) {
        valid = false;
        nameErr = "Cannot be blank";
    } else if (state.name.trim().length !== state.name.length) {
        valid = false;
        nameErr = "Starts/ends with blanks";
    }

    if (state.status.length === 0) {
        valid = false;
        statusErr = "Required";
    } else if (state.status.trim().length === 0) {
        valid = false;
        statusErr = "Cannot be blank";
    } else if (state.status.trim().length !== state.status.length) {
        valid = false;
        statusErr = "Starts/ends with blanks";
    }
    // if (!state.item && valid) {
    //     valid = false;
    //     statusErr = "Existing syndicate does not exist.";
    // }

    state.valid = valid;
    state.nameError = nameErr;
    state.statusError = statusErr;

    var item = state.item;
    if (valid) {
        var syndicate : SyndicateOverviewItem | undefined = undefined;

        var syndicateUpdate : SyndicateUpdate | undefined = { };
        var changeCount = 0;
        if (item) {
            syndicate = {...item, name: state.name};
            syndicate.status = state.status;
            if (syndicate.name !== item.name) {
                syndicateUpdate.name = syndicate.name;
                changeCount++;
            }
            if (syndicate.status !== item.status) {
                syndicateUpdate.status = syndicate.status;
                changeCount++;
            }
             if (changeCount <= 0) {
                syndicateUpdate = undefined;
            }
        } else {
            //No create/insert
        }
        state.value = syndicate;
        state.update = syndicateUpdate;
    } else {
        state.value = undefined;
        state.update = undefined;
    }
}

function updateState(state: EditSyndicateState, item: SyndicateOverviewItem) {
    state.updateNo += 1;
    state.item = item;
    state.id = item.id;
    state.name = item.name;
    state.status = item.status;
    checkState(state);
}

function getInitialState() : EditSyndicateState {
    var state : EditSyndicateState = {
        updateNo: 0,
        id: 0,
        name: "",
        status: "",
        valid: false,
        nameError: "",
        statusError: "",
    };
    checkState(state);
    return state;
}


const EditSyndicate: React.FC = (props) => {
    const location = useLocation();
    const history = useHistory();    
   
    //const [formState, setFormState] = useState<EditSyndicateState>(getInitialState());
    const [formState, setFormState] = useState<EditSyndicateState>({
        updateNo: 0,
        id: 0,
        name: "",
        status: "",
        valid: false,
        nameError: "",
        statusError: "",
    });
    const [saveError, setSaveError] = useState<string>("");
    const [saveStatus, setSaveStatus] = useState<number>(0);
    const [initErr, setInitErr] = useState<string>("");
    const [refreshing, setRefreshing] = useState<boolean>(false);

    useEffect(() => {
        initData().then(result => {
        });
    }, []);

    const initData = async () : Promise<number> => {
        var initCount = 0;
        setInitErr("Initializing...");
        setRefreshing(true);
        try {   
            setFormState(getInitialState());
            var pos = location.pathname.lastIndexOf("/");
            if (pos <= 0) {
                setInitErr("");
                return initCount;
            }
            var idString = location.pathname.substring(pos + 1);
            var id = parseInt(idString);
            if (isNaN(id) || id <= 0) {
                setInitErr(`SyndicateId param is invalid '${idString}'.`);
                return initCount;
            }
            var fs = {...formState, id: id};
            var resp = await AgentUtil.getSyndicate(id);
            if (resp.success) {
                setInitErr("");
                initCount++;
            } else {
                setInitErr(`Init error: ${resp.message}`);
                return initCount;
            }
            var agent = resp.item;
            if (agent) {
                updateState(fs, agent); 
            } else {
                setInitErr("Syndicate not found.")
            }
            setFormState(fs);
        } catch (error) {
            console.error("Init error.", error);
            setInitErr(`Init error: ${error}`);
        } finally {
            setRefreshing(false);
        }

        return initCount;
    }
    const handleChange = (name: string, value:any) => {
        var fs = {...formState, [name]: value};
        checkState(fs);
        setFormState(fs);
    }
    const onOperatorChange = (item?: Operator) : void => {
        var fs = {...formState, ["operatorId"]: item ? item.id : ""};
        checkState(fs);
        setFormState(fs);
    }
    const onSave = () => {
        const METHOD_NAME = "SaveAgent";
        
        setSaveError("");
        setSaveStatus(0);
        if (!formState.valid) {
            setSaveError("Input is not valid");
            return;
        }
        if (!formState.update) {
            setSaveError("No changes");
            return;
        }
        setSaveStatus(1);
        var saveJson = JSON.stringify(formState.update);
        AgentUtil.updateSyndicate(formState.id, saveJson).then(resp => {
            setSaveStatus(0);
            if (resp.success){
                var item = resp.item;
                if (item) {
                    if (!formState.id || formState.id <= 0) {
                        history.push(`editAgent/${item.id}`);
                        return;
                    }
                    var fs = {...formState, id: item.id};
                    updateState(fs, item);
                    setFormState(fs);
                    return;
                } 
                setSaveError(`${METHOD_NAME} failure: Agent was not returned.`);

            } else {
                setSaveError(resp.message);
            }
        }).catch(error => {
            setSaveError(`${METHOD_NAME} error: ${error}`);
            setSaveStatus(0);
        });
           
    };

    var saveEnabled = false;
    var saveVisible = true;
    var syndicate = formState.item;
    if (formState.valid && formState.update && !refreshing && saveStatus === 0) {
        saveEnabled = true;
    }
    var initErrorBox = null;
    if (initErr && initErr.length > 0) {
        initErrorBox = (<Box marginTop={3} color="red">{initErr}</Box>);
        saveEnabled = false;
    }

    var saveErrorBox = null;
    if (saveError.length > 0) {
        saveErrorBox = (<Box color="red">{saveError}</Box>);
    }

    var readOnlyProps = { readOnly: true, disableUnderline: true };
    var nameCtrl = null;
    var statusCtrl = null;
    var editEnabled = false;
    if (formState.item) {
        var now = new Date();
        var deadline = new Date(formState.item.deadline);
        if (deadline > now && formState.item.nShares < formState.item.maxShares) {
            editEnabled = true;
        }

        if (editEnabled) {
            nameCtrl = (
                <TextField
                    label="Name"
                    name="name"
                    autoFocus={true}
                    value={formState.name}
                    onChange={e => handleChange("name", e.target.value)}
                    style={{width:400}}
                    error={formState.nameError.length > 0}
                    helperText={formState.nameError}
                />
            );

            var statusArray : string[] = [];
            if (formState.item.status === AgentUtil.SYNDICATE_STATUS_DRAFT) {
                statusArray = [AgentUtil.SYNDICATE_STATUS_DRAFT, AgentUtil.SYNDICATE_STATUS_SUBMITTED];
            } else if (formState.item.status === AgentUtil.SYNDICATE_STATUS_SUBMITTED || formState.item.status === AgentUtil.SYNDICATE_STATUS_PAUSED) {
                statusArray = [AgentUtil.SYNDICATE_STATUS_SUBMITTED, AgentUtil.SYNDICATE_STATUS_PAUSED];
            }
            statusCtrl = (
                <TextField
                    label="Status"
                    select
                    name="status"
                    value={formState.status}
                    onChange={e => handleChange("status", e.target.value)}
                    style={{width:400}}
                    error={formState.statusError.length > 0}
                    helperText={formState.statusError}
                >
                    {statusArray.map(status => (
                    <MenuItem key={`status_${status}`} value={status}>
                        {status}
                    </MenuItem>
                    ))}
                </TextField>
            );
        }
    }
    var title = syndicate ? `Edit Syndicate #${syndicate.id}` : "View Syndicate";
    if (!syndicate) {
        saveVisible = false;
    }
    if (!editEnabled) {
        saveEnabled = false;
        saveVisible = false;
        if (syndicate) {
             title = `View Syndicate #${syndicate.id}`;
        }
    }
    if (!nameCtrl) {
        nameCtrl = (
            <TextField
                autoFocus
                label="Name"
                name="name"
                value={formState.item?.name ?? ""}
                InputProps={readOnlyProps}
                style={{width:400}}
            />

        );
    }
    if (!statusCtrl) {
        statusCtrl = (
            <TextField
                label="Status"
                name="status"
                value={formState.status}
                style={{width:400}}
                error={formState.statusError.length > 0}
                helperText={formState.statusError}
                InputProps={readOnlyProps}
            />
        );
    }
    var roundInfoBox = null;
    var item = formState.item;
    if (item) {
        const roundTitle = RoundOverviewUtil.getRoundTitle2(item.roundId, item.roundTypeName, item.deadline, item.roundStatus);
        roundInfoBox  = (<Box><h5>{`Round: ${roundTitle}`}</h5></Box>);
    } else {
        roundInfoBox  = (<Box><h5>{"Round:"}</h5></Box>);
    }
    var gotoSyndicateBtn = null;
    if (syndicate) {
        gotoSyndicateBtn = (
 
        <Button
            component={RouterLink}
            color="secondary"
            size="small"
            style={{textTransform:"none"}}
            to={`/syndicates/${syndicate.id}`}
            >
            Goto Syndicate
        </Button>
        );

    }
    return (
        <Container maxWidth="xl">
            <Title title={title} />
            <Box>
                <TextField
                    label="Id"
                    name="id"
                    value={formState.id ? formState.id : "0"}
                    helperText={formState.item ? `CreatedAt: ${CreateRoundUtil.toGameDateString(formState.item.createdAt)}` : ""}
                    InputProps={{
                        readOnly: true, disableUnderline: true
                        }}
                />
            </Box>
            <Box marginTop={2}>
                {roundInfoBox}
            </Box>
            <Box marginTop={1}>
                {nameCtrl}
            </Box>
            <Box marginTop={1}>
                {statusCtrl}
            </Box>
            <Box marginTop={1}>
                <TextField
                    label="Operator"
                    name="operatorId"
                    value={formState.item?.operatorId ?? ""}
                    InputProps={readOnlyProps}
                    style={{width:400}}
                />
            </Box>
            <Box marginTop={1}>
                <TextField
                    label="Agent"
                    name="agentName"
                    value={formState.item?.agentName ?? ""}
                    InputProps={readOnlyProps}
                    style={{width:400}}
                />
            </Box>
            <Box marginTop={1}>
                <TextField
                    label="Agent fee%"
                    name="agentFeePercentage"
                    value={formState.item?.agentFeePercentage !== null && formState.item?.agentFeePercentage !== undefined ? formatToPercent(formState.item.agentFeePercentage) :  ""}
                    InputProps={readOnlyProps}
                />
            </Box>
            <Box marginTop={1}>
                <TextField
                    label="Share price"
                    name="shareAmount"
                    value={formState.item ? formatToCurrency(formState.item.shareAmount, formState.item.shareCurrency) :  ""}
                    InputProps={readOnlyProps}
                />
            </Box>
            <Box marginTop={1}>
                <TextField
                    label="Sold / Max shares"
                    name="soldShares"
                    value={formState.item ? `${formState.item.nShares} / ${formState.item.maxShares}` :  ""}
                    InputProps={readOnlyProps}
                />
            </Box>
            {initErrorBox} 
            <Box marginTop={2}>
                <Button onClick={e => onSave()} color="primary" variant="contained" disabled={!saveEnabled}>
                    Save
                </Button>
            </Box>
            {saveErrorBox} 
            <Box>
            <Button
                    component={RouterLink}
                    color="secondary"
                    size="small"
                    style={{textTransform:"none", marginRight:"5px"}}
                    to={"/agentOverview"}
                    >
                    Goto Agent Overview
                </Button>
                {gotoSyndicateBtn}
            </Box>
            {/* <Box>
                <pre>{JSON.stringify(formState, undefined, 2)}</pre>
            </Box> */}
        </Container>
    );
};

export default EditSyndicate;
