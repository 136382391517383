import React, { useEffect, useState } from "react";
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, MenuItem, FormControlLabel, Switch } from '@material-ui/core';
import { ExtFixture, ExtFixtureMapping, ExtFixtureParticipant, MapFixtureRequest } from "./FixtureOverviewModels";
import { Fixture } from "../TeamOverview/TeamOverviewModels";
import { League } from "../LeagueOverview/LeagueModels";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import FixtureOverviewUtil from "./FixtureOverviewUtil";
import RoundOverviewUtil from "../RoundOverview/RoundOverviewUtil";
import LeagueUtil from "../LeagueOverview/LeagueUtil";

interface MapFixtureDialogProps {
    open: boolean;
    fixture?: Fixture;
    providerId: string;
    league?: League;
    extFixture: ExtFixture;
    timestamp: number;
    onClose: (extFixture: ExtFixture | undefined) => void;
}
interface MapFixtureDialogState {
    timestamp: number;
    extFixture: ExtFixture;
    extFixtureTitle: string;
    extFixtureSubtitle: string;
    fixtureId: number;
    fixtureName: string;
    fixtureTitle: string;
    fixtureSubtitle: string;
    providerId: string;
    reversed: boolean;
    mustConfirm: boolean;
    mustConfirms: string[];
    confirmed: boolean;
    valid: boolean;
    extFixtureError: string;
    fixtureError: string;
    providerError: string;
    confirmedError: string;
    update?: MapFixtureRequest;
}

function checkState(state : MapFixtureDialogState) {
    var extFixture = state.extFixture;
    var valid = true;
    var extFixtureErr = "";
    var fixtureErr = "";
    var providerErr = "";
    var confirmedErr = "";

    if (extFixture.mapping && extFixture.mapping.id > 0) {
        valid = false;
        extFixtureErr = "Ext fixture is alread mapped";
    }
    if (state.fixtureId <= 0) {
        valid = false;
        fixtureErr = "Fixture not specified";
    }
    if (!state.providerId || state.providerId.length <= 0) {
        valid = false;
        fixtureErr = "Provider not specified";
    }

    if (state.mustConfirm && !state.confirmed) {
        valid = false;
        confirmedErr = "Confirmation mandatory";
    }

    state.valid = valid;
    state.extFixtureError = extFixtureErr;
    state.fixtureError = fixtureErr;
    state.providerError = providerErr;
    state.confirmedError = confirmedErr;

    if (valid) {
        var homeParticipant : ExtFixtureParticipant | undefined = undefined;
        var awayParticipant : ExtFixtureParticipant | undefined = undefined;
        if (extFixture.participants && extFixture.participants.length === 2) {
            homeParticipant = extFixture.participants[0];
            awayParticipant = extFixture.participants[1];
            if (homeParticipant.ordNo > awayParticipant.ordNo) {
                var homeParticipantX = homeParticipant;
                homeParticipant = awayParticipant;
                awayParticipant = homeParticipantX;
            }
        }
        var mapRequest : MapFixtureRequest = {
            fixtureId: state.fixtureId,
            providerId: state.providerId,
            extFixtureId: state.extFixture.id,
            extFixtureName: state.extFixture.name,
            reversed: state.reversed,
            extHomeName: homeParticipant ? homeParticipant.name : "",
            extAwayName: awayParticipant ? awayParticipant.name : ""
        };
        state.update = mapRequest;

    } else {
        state.update = undefined;
    }
}

function getFixtureTitle(id: number | string, name: string, startingAt: Date, status: string, league: string) : string {
    var title = `Id: ${id} | Name: ${name} | Starting at: ${CreateRoundUtil.toGameDateString(startingAt)} | Status: ${status} | League: ${league}`;
    return title;
}
function getFixtureSubtitle(id: number | string, startingAt: Date, status: string, league: string) : string {
    //var title = `Id: ${id} | Starting at: ${CreateRoundUtil.toGameDateString(startingAt)} | Status: ${status} | League: ${league}`;
    var title = `${id} | ${CreateRoundUtil.toGameDateString(startingAt)} | ${league} | ${status}`;
    return title;
}
const MapFixtureDialog: React.FC<MapFixtureDialogProps> = (props) => {
    const getDefaultFormState = () : MapFixtureDialogState => {
        var fixture = props.fixture;
        var extFixture = props.extFixture;
        var extFixtureTitle = getFixtureTitle(extFixture.id, extFixture.name, extFixture.startingAt, extFixture.status, "");
        var extFixtureSubtitle = getFixtureSubtitle(extFixture.id, extFixture.startingAt, extFixture.status, "");
        var mapping = extFixture.mapping;
        var fixtureId = 0;
        var fixtureName = "";
        var fixtureTitle = "";
        var fixtureSubtitle = "";
        var mustConfirm = false;
        var mustConfirms : string[] = [];
        var reversed = mapping ? mapping.reversed : false;
        if (fixture) {
            fixtureId = fixture.id;
            fixtureName = fixture.name;
            fixtureTitle = getFixtureTitle(fixture.id, fixture.name, fixture.startingAt, fixture.status, fixture.leagueName);
            fixtureSubtitle = getFixtureSubtitle(fixture.id, fixture.startingAt, fixture.status, fixture.leagueName);
            if (mapping) {
                if (fixtureId !== mapping.fixtureId) {
                    mustConfirms.push("Fixture-Mapping id diff");
                    var diffMsecs = Math.abs(new Date(fixture.startingAt).getTime() - new Date(extFixture.startingAt).getTime());
                    var diffMinutes = Math.floor(diffMsecs / 1000 / 60);
                    if (diffMinutes > 60) {
                        mustConfirms.push(`Starting at diff exceeds 60 minutes [diff: ${diffMinutes}]`);
                    }
                }
            }
        } else if (mapping) {
            fixtureId = mapping.fixtureId;
            fixtureName = mapping.fixtureName;
            fixtureTitle = getFixtureTitle(mapping.fixtureId, mapping.fixtureName, mapping.startingAt, mapping.fixtureStatus, mapping.leagueName);
            fixtureSubtitle = getFixtureSubtitle(mapping.fixtureId, mapping.startingAt, mapping.fixtureStatus, mapping.leagueName);
        }

        if (reversed) {
            mustConfirms.push("Reversed");
        }

        var fs : MapFixtureDialogState = {
            timestamp: props.timestamp,
            extFixture: props.extFixture,
            extFixtureTitle: extFixtureTitle,
            extFixtureSubtitle: extFixtureSubtitle,
            fixtureId: fixtureId,
            fixtureName: fixtureName,
            fixtureTitle: fixtureTitle,
            fixtureSubtitle: fixtureSubtitle,
            providerId: props.providerId,
            reversed: reversed,
            mustConfirm: (mustConfirms.length > 0),
            mustConfirms: mustConfirms,
            confirmed: false,
            valid: false,
            extFixtureError: "",
            fixtureError: "",
            providerError: "",
            confirmedError: ""
        };
        checkState(fs);
        return fs;
    }
    
    const [open, setOpen] = useState<boolean>(props.open);
    const [formState, setFormState] = useState<MapFixtureDialogState>({
        timestamp: props.timestamp,
        extFixture: props.extFixture,
        extFixtureTitle: "",
        extFixtureSubtitle: "",
        fixtureId: 0,
        fixtureName: "",
        fixtureTitle: "",
        fixtureSubtitle: "",
        providerId: props.providerId,
        reversed: false,
        mustConfirm: false,
        mustConfirms: [],
        confirmed: false,
        valid: false,
        extFixtureError: "",
        fixtureError: "",
        providerError: "",
        confirmedError: ""
    }
    );
    const [extFixture, setExtFixture] = useState<ExtFixture>(props.extFixture);
    const [saveError, setSaveError] = useState<string>("");
    const [saveStatus, setSaveStatus] = useState<number>(0);

    var propsFixtureId = props.fixture ? props.fixture.id : 0;
    var propsExtFixtureId = props.extFixture.id;
    useEffect(() => {
        setFormState(getDefaultFormState());
    }, [props.timestamp, propsFixtureId, propsExtFixtureId]);

    const handleClickOpen = () => {
        setOpen(true);
        setFormState(getDefaultFormState());
    };

    const handleDialogClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) : void => {
        e.stopPropagation();
    }; 

    const handleClose = () => {
        setOpen(false);
        if (props.onClose) {
            props.onClose(undefined);
        }
    };
    const handleChange = (name: string, value:string) =>{
        //var prevValue = formState.[name];
        var fs = {...formState, [name]: value};
        checkState(fs);
        setFormState(fs);
    }
    const onConfirmedChanged = () => {
        var confirmed = !formState.confirmed;
        var fs = {...formState, confirmed: confirmed}
        checkState(fs);
        setFormState(fs);
    }
    const onSave = () => {
        const METHOD_NAME = "MapFixture";

        setSaveError("");
        setSaveStatus(0);
        if (!formState.valid) {
            setSaveError("Input is not valid");
            return;
        }
        if (!formState.update) {
            setSaveError("No changes");
            return;
        }
        setSaveStatus(1);
        var saveJson = JSON.stringify(formState.update);
        FixtureOverviewUtil.mapExtFixture(formState.update).then(resp => {
            setSaveStatus(0);
            if (resp.success){
                var mapping = resp.item;
                if (mapping) {
                    var extMapping : ExtFixtureMapping = {
                        id: mapping.id,
                        fixtureId: mapping.fixtureId,
                        fixtureName: mapping.fixtureName,
                        leagueId: mapping.leagueId,
                        leagueName: mapping.leagueName,
                        status: "REGISTERED",
                        reversed: mapping.reversed,
                        homeTeamId: mapping.homeTeamId,
                        awayTeamId: mapping.awayTeamId,
                        homeTeamName: mapping.homeTeamName,
                        awayTeamName: mapping.awayTeamName,
                        startingAt: mapping.startingAt,
                        fixtureStatus: mapping.fixtureStatus,
                    }
                    var extFixture = {... props.extFixture, mapping: extMapping};
                    setOpen(false);
                    if (props.onClose) {
                        props.onClose(extFixture);
                    }
                    return;
                } 
                setSaveError(`${METHOD_NAME} failure: FixtureMapping was not returned.`);

            } else {
                setSaveError(resp.message);
            }
        }).catch(error => {
            setSaveError(`${METHOD_NAME} error: ${error}`);
            setSaveStatus(0);
        });           
    };
    var saveEnabled = (formState.valid && saveStatus === 0 && formState.update);
    var saveErrorBox = null;
    if (saveError.length > 0) {
        saveErrorBox = (<Box color="red">{saveError}</Box>);
    }

    var mustConfirmBox = null;
    if (formState.mustConfirm) {
        mustConfirmBox = (
            <Box>
                <ul style={{ color: RoundOverviewUtil.INVALID_COLOR }}>
                    {formState.mustConfirms.map((msg, index) => (
                        <li>{msg}</li>
                    ))}
                </ul>
                <FormControlLabel control={<Switch
                        checked={formState.confirmed}
                        onChange={(e: any) => onConfirmedChanged()}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />}
                    label="Confirmed"                   
                />
            </Box>

        );
    }
    return (
        <div>
            {props.open ? null : (
                <Button onClick={handleClickOpen} variant="outlined" color="secondary" size="small">
                    Map fixture
                </Button>
            )}
        <Dialog
            open={open}
            onClose={handleClose}
            onClick={e => handleDialogClick(e)}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Map fixture</DialogTitle>
            <DialogContent dividers>
                {/* <DialogContentText>
                    To subscribe to this website, please enter your email address here. We will send updates
                    occasionally.
                </DialogContentText> */}
                <Box display="flex" alignItems="center">
                    <TextField
                        label="Fixture"
                        name="fixtureTitle"
                        value={formState.fixtureName}
                        helperText={formState.fixtureError ? formState.fixtureError : formState.fixtureSubtitle}
                        error={(formState.fixtureError.length > 0)}
                        style={{width:600}}
                        InputProps={{
                        readOnly: true,
                        }}
                    />
                </Box>
                <Box display="flex" alignItems="center">
                    <TextField
                        label="Provider"
                        name="providerId"
                        value={formState.providerId}
                        helperText={formState.providerError}
                        error={(formState.providerError.length > 0)}
                        InputProps={{
                        readOnly: true,
                        }}
                    />
                </Box>
                <Box display="flex" alignItems="center">
                    <TextField
                        label="Ext Fixture"
                        name="extFixtureTitle"
                        value={formState.extFixture.name}
                        helperText={formState.extFixtureError ? formState.extFixtureError : formState.extFixtureSubtitle}
                        error={(formState.extFixtureError.length > 0)}
                        style={{width:600}}
                        InputProps={{
                        readOnly: true,
                        }}
                    />
                </Box>
                {mustConfirmBox}
                {saveErrorBox} 
                {/* <Box>
                    FormState:<br></br>
                    <pre>{JSON.stringify(formState, undefined, 2)}</pre>
                </Box> */}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={onSave} color="primary" disabled={!saveEnabled}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
};

export default MapFixtureDialog;
