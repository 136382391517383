import React, { useEffect, useState } from "react";
import { useLocation, Link as RouterLink, useHistory } from 'react-router-dom';
import { Button, TextField, Box, MenuItem, Container, FormControlLabel, Switch } from '@material-ui/core';
import { Title } from "react-admin";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import AdminUtil from "../Admin/AdminUtil";
import { BaseItemState } from "../Ticker2/Ticker2Models";
import { Operator, OperatorSetting } from "../Admin/AdminModels";
import OperatorSelect from "../Components/OperatorSelect";
import AgentUtil from "./AgentUtil";
import { Player, Agent, AgentUpdate } from "./AgentModels";
import { GenericResponse } from "../CreateRound/CreateRoundModels";
import EditAgentPlayerTable from "./EditAgentPlayerTable";
import ImagePicker, { FileUploadInfo } from "../Components/ImagePicker";
import { imageFileTypes, renderImgFromArray } from "../common/helpers/ImageHelpers";
import ImageView from "../Components/ImageView";

interface EditAgentState {
    updateNo: number;
    item?: Agent;
    players: Player[];
    id: number;
    name: string;
    operatorId: string;
    feePercentage: string;
    currency: string;
    ordNo: string;
    profileText: string;
    valid: boolean;
    nameError: string;
    operatorIdError: string;
    feePercentageError: string;
    currencyError: string;
    playersError: string;
    ordNoError: string;
    profileTextError: string;
    avatarImageError: string;
    value?: Agent;
    update?: AgentUpdate;
}
interface AddPlayerState {
    playerId: string;
    valid: boolean;
    playerIdErr: string;
}
interface RemovePlayerState {
    playerId: number;
    handled: boolean;
}
function parseFloatX(s: string) : number {
    if (!s || s.length <= 0) {
        return NaN;
    }
    var f = parseFloat(s);
    if (!isNaN(f)) {
        var s2 = f.toString();
        if (s2 === s) {
            return f;
        }
    }
    //return NaN;
    var dsep=(1/2).toLocaleString().charAt(1);
    // if (s.charAt(0) < '0' || s. )
    const decimalNumberPattern = "^-?\\d+\\" + dsep + "?\\d+$|^-?\\d+$";
    var regex = new RegExp(decimalNumberPattern);
    if (!regex.test(s)) {
        return NaN;
    }
    var tsep= (dsep === ".") ? "," : ".";
    var s2 = s.replaceAll(/\s/g, "");
    s2 = s2.replaceAll(tsep, "");
    if (dsep !== ".") {
        s2 = s2.replaceAll(dsep, ".");
    }
    f = parseFloat(s2);
    if (!isNaN(f)) {
        var s3 = f.toString();
        if (s3 === s2) {
            return f;
        }
        if (s2.length > s3.length && s2.charAt(s.length - 1) === "0" && f !== 0) {
            return f;
        }
        return NaN;
    }
    return f;
}
function getDecimalCount(d: number) {
    if (!d) {
        return 0;
    }
    if (Number.isInteger(d)) {
        return 0;
    }
    var splits = d.toString().split(".");
    if (splits.length < 2) {
        return 0;
    }
    return splits[1].length;
}
function checkState(state : EditAgentState) {
    var feePercentage = parseFloatX(state.feePercentage);
    var ordNo = parseInt(state.ordNo);

    var valid = true;
    var nameErr = "";
    var operatorErr = "";
    var feePercentageErr = "";
    var currencyErr = "";
    var playersErr = "";
    var ordNoErr = "";
    var profileTextErr = "";
    var avatarImageErr = "";

    if (state.name.length === 0) {
        valid = false;
        nameErr = "Required";
    } else if (state.name.trim().length === 0) {
        valid = false;
        nameErr = "Cannot be blank";
    } else if (state.name.trim().length !== state.name.length) {
        valid = false;
        nameErr = "Starts/ends with blanks";
    }

    if (state.operatorId.length === 0) {
        valid = false;
        operatorErr = "Required";
    } else if (state.operatorId.trim().length === 0) {
        valid = false;
        operatorErr = "Cannot be blank";
    } else if (state.operatorId.trim().length !== state.operatorId.length) {
        valid = false;
        operatorErr = "Starts/ends with blanks";
    }

    if (state.feePercentage.length === 0) {
        valid = false;
        feePercentageErr = "Required number";
    } else if (isNaN(feePercentage)) {
        valid = false;
        feePercentageErr = "Invalid number";
    } else if (feePercentage < 0 || feePercentage > 0.15) {
        valid = false;
        feePercentageErr = "Number must be range 0-0.15";
    } else if (getDecimalCount(feePercentage) > 4) {
        valid = false;
        feePercentageErr = "Number cannot have more than 4 decimals";
    }

    if (state.currency.length > 0) {
        if (state.currency.trim().length !== state.currency.length) {
            valid = false;
            currencyErr = "Starts/ends with blanks";
        }
    }

    if (state.ordNo.length === 0) {
        valid = false;
        ordNoErr = "Required number";
    } else if (isNaN(ordNo)) {
        valid = false;
        ordNoErr = "Invalid number";
    }

    if (state.profileText.length > 0) {
        if (state.profileText.trim().length !== state.profileText.length) {
            valid = false;
            profileTextErr = "Starts/ends with blanks";
        } else if (state.profileText.length > 2000) {
            valid = false;
            profileTextErr = "Length exceeds 2000 characters";
        }
    }

    if (state.players.length > 0) {
        var player = state.players.find(it => it.operatorId !== state.operatorId);
        if (player) {
            valid = false;
            playersErr = `There is at least one player from another operator [id:${player.id}]`;
        } else {
            if (state.players.length > 1) {
                var uniquePlayers = state.players.map(p => p.id).filter((value, index, array) => array.indexOf(value) === index);
                if (uniquePlayers.length != state.players.length) {
                    valid = false;
                    playersErr = "At least one player exists more than once";
                }
            }
        }
    }

    state.valid = valid;
    state.nameError = nameErr;
    state.operatorIdError = operatorErr;
    state.feePercentageError = feePercentageErr;
    state.currencyError = currencyErr;
    state.playersError = playersErr;
    state.ordNoError = ordNoErr;
    state.profileTextError = profileTextErr;
    state.avatarImageError = avatarImageErr;

    if (valid) {
        var item = state.item;
        var agent : Agent = {
            createdAt: item ? item.createdAt : new Date(),
            id: item ? item.id : 0,
            name: state.name,
            operatorId: state.operatorId,
            feePercentage: feePercentage,
            currency: state.currency,
            ordNo: ordNo,
            profileText: state.profileText,
            avatarImageSize: item?.avatarImageSize ? item.avatarImageSize : 0,
            avatarImageType: item?.avatarImageType ? item.avatarImageType : "",
            playerIds: state.players.map(p => p.id),
            players: state.players
        };
        state.value = agent;

        var agentUpdate : AgentUpdate | undefined = { };
        var changeCount = 0;
        if (item) {
            if (agent.name !== item.name) {
                agentUpdate.name = agent.name;
                changeCount++;
            }
            if (agent.operatorId !== item.operatorId) {
                agentUpdate.operatorId = agent.operatorId;
                changeCount++;
            }
            if (agent.feePercentage !== item.feePercentage) {
                agentUpdate.feePercentage = agent.feePercentage;
                changeCount++;
            }
            if (agent.currency !== item.currency) {
                agentUpdate.currency = agent.currency;
                changeCount++;
            }
            if (agent.ordNo !== item.ordNo) {
                agentUpdate.ordNo = agent.ordNo;
                changeCount++;
            }
            if (agent.profileText !== item.profileText) {
                agentUpdate.profileText = agent.profileText;
                changeCount++;
            }
            if (agent.playerIds.length !== item.playerIds.length) {
                agentUpdate.players = agent.playerIds.map(id => ({ playerId: id }));
                changeCount++;
            } else {
                var playerChanged = false;
                //Any added players?
                for (const playerId of agent.playerIds) {
                    var existingPlayerId = item.playerIds.find(it => it === playerId);
                    if (!existingPlayerId) {
                        playerChanged = true;
                        break;
                    }
                }
                //Any removed players?
                if (!playerChanged) {
                    for (const playerId of item.playerIds) {
                        var newPlayerId = agent.playerIds.find(it => it === playerId);
                        if (!newPlayerId) {
                            playerChanged = true;
                            break;
                        }
                    }
                }
                if (playerChanged) {
                    //agentUpdate.playerIds = agent.playerIds;
                    agentUpdate.players = agent.playerIds.map(id => ({ playerId: id }));
                    changeCount++;
                }
            }
             if (changeCount <= 0) {
                agentUpdate = undefined;
            }
        } else {
            agentUpdate.name = agent.name;
            agentUpdate.operatorId = agent.operatorId;
            agentUpdate.feePercentage = agent.feePercentage;
            agentUpdate.currency = agent.currency;
            agentUpdate.ordNo = agent.ordNo;
            agentUpdate.profileText = agent.profileText;
            agentUpdate.players = agent.playerIds.map(id => ({ playerId: id }));
        }
        state.update = agentUpdate;
    } else {
        state.value = undefined;
        state.update = undefined;
    }
}

function updateState(state: EditAgentState, item: Agent) {
    if (item.currency === null || item.currency === undefined) {
        item.currency = "";
    }
    state.updateNo += 1;
    state.item = item;
    state.id = item.id;
    state.name = item.name;
    state.operatorId = item.operatorId;
    state.feePercentage = item.feePercentage.toString();
    state.currency = item.currency;
    state.ordNo = item.ordNo === null || item.ordNo === undefined ? "0" : item.ordNo.toString();
    state.profileText = item.profileText ? item.profileText : "";
    state.players = item.players;
    checkState(state);
}
function getDefaultValidFrom() : Date {
    var validFrom = CreateRoundUtil.addHours(new Date(), 1);
    var roundedValidFrom = CreateRoundUtil.getDateWithoutTime(validFrom);
    var minutes = validFrom.getMinutes();
    if (minutes === 0){
      roundedValidFrom = CreateRoundUtil.addHours(roundedValidFrom, validFrom.getHours());
    } else {
      roundedValidFrom.setHours(validFrom.getHours() + 1, 0);
    }
    return roundedValidFrom;
}
function getDefaultValidTo(validFrom: Date) : Date {
    return CreateRoundUtil.addDays(validFrom, 7);
}
function getInitialState() : EditAgentState {
    var validFrom = getDefaultValidFrom();
    var validTo = getDefaultValidTo(validFrom);
    var state : EditAgentState = {
        updateNo: 0,
        id: 0,
        name: "",
        operatorId: "",
        feePercentage: "",
        currency: "",
        ordNo: "",
        profileText: "",
        players: [],
        valid: false,
        nameError: "",
        operatorIdError: "",
        feePercentageError: "",
        currencyError: "",
        ordNoError: "",
        profileTextError: "",
        avatarImageError: "",
        playersError: ""
    };
    checkState(state);
    return state;
}

const EditAgent: React.FC = (props) => {
    const location = useLocation();
    const history = useHistory();    
   
    //const [formState, setFormState] = useState<EditAgentState>(getInitialState());
    const [formState, setFormState] = useState<EditAgentState>({
        updateNo: 0,
        id: 0,
        name: "",
        operatorId: "",
        feePercentage: "",
        currency: "",
        ordNo: "",
        profileText: "",
        players: [],
        valid: false,
        nameError: "",
        operatorIdError: "",
        feePercentageError: "",
        currencyError: "",
        ordNoError: "",
        profileTextError: "",
        avatarImageError: "",
        playersError: ""
    });
    const [avatarImageInfo, setAvatarImageInfo] = useState<string>("");
    const [addPlayerState, setAddPlayerState] = useState<AddPlayerState>({
        playerId: "",
        valid: false,
        playerIdErr: ""
    });
    const [removePlayerState, setRemovePlayerState] = useState<RemovePlayerState>({
        playerId: 0,
        handled: false
    });
    const [saveError, setSaveError] = useState<string>("");
    const [saveStatus, setSaveStatus] = useState<number>(0);
    const [initErr, setInitErr] = useState<string>("");
    const [refreshing, setRefreshing] = useState<boolean>(false);

    useEffect(() => {
        initData().then(result => {
        });
    }, []);

    const updateAvatarImageInfo = (state: EditAgentState) => {
        var agent = state.item;
        if (agent && agent.avatarImageSize && agent.avatarImageSize > 0) {
            setAvatarImageInfo(`File type: ${agent.avatarImageType} | Size:  ${agent.avatarImageSize}`);
        } else {
            setAvatarImageInfo("");
        }
    }
    const initData = async () : Promise<number> => {
        var initCount = 0;
        setInitErr("Initializing...");
        setRefreshing(true);
        try {   
            setFormState(getInitialState());
            var pos = location.pathname.lastIndexOf("/");
            if (pos <= 0) {
                setInitErr("");
                return initCount;
            }
            var idString = location.pathname.substring(pos + 1);
            var id = parseInt(idString);
            if (isNaN(id) || id <= 0) {
                setInitErr(`AgentId param is invalid '${idString}'.`);
                return initCount;
            }
            var fs = {...formState, id: id};
            var resp = await AgentUtil.getAgent(id);
            if (resp.success) {
                setInitErr("");
                initCount++;
            } else {
                setInitErr(`Init error: ${resp.message}`);
                return initCount;
            }
            var agent = resp.item;
            if (agent) {
                updateState(fs, agent); 
            } else {
                setInitErr("Agent not found.")
            }
            setFormState(fs);
            updateAvatarImageInfo(fs);
        } catch (error) {
            console.error("Init error.", error);
            setInitErr(`Init error: ${error}`);
            setAvatarImageInfo("");
        } finally {
            setRefreshing(false);
        }

        return initCount;
    }
    const handleChange = (name: string, value:any) => {
        var fs = {...formState, [name]: value};
        checkState(fs);
        setFormState(fs);
    }
    const handleAddPlayerChange = (name: string, value:any) => {
        var state = {...addPlayerState, [name]: value};
        state.valid = (state.playerId.length > 0);
        state.playerIdErr = "";
        setAddPlayerState(state);
    }
    const onOperatorChange = (item?: Operator) : void => {
        var fs = {...formState, operatorId: item ? item.id : ""};
        checkState(fs);
        setFormState(fs);
    }
    const onAvatarImageChange = (info: FileUploadInfo) : void => {
        setAvatarImageInfo(`Valid:${info.valid} | Message: ${info.message} | File name: ${info.fileName} | File type:  ${info.fileType} | Size:  ${info.byteCount}`);
        if (!info.valid) {
            return;
        }
        if (!formState.item) {
            return;
        }
        var agent : Agent = formState.item;
        agent.avatarImageSize = info.byteCount;
        agent.avatarImageType = info.fileType;
    }
    const onAvatarImageRemove = () : void => {
        setAvatarImageInfo("");
        if (!formState.item) {
            return;
        }
        var agent : Agent = formState.item;
        agent.avatarImageSize = 0;
        agent.avatarImageType = "";
    }

    const onAddPlayer = () : void => {
        const METHOD_NAME = "Add Player";
        var status = "Adding player";
        if (!formState.item) {
            status = "Agent is not saved.";
            var state = {...addPlayerState, ["playerIdErr"]: status};
            state.valid = false;
            setAddPlayerState(state);
            return;
        }
        var agent : Agent = formState.item;
        if (!addPlayerState.playerId || addPlayerState.playerId.length <= 0) {
            status = "Player Id not specified.";
            var state = {...addPlayerState, ["playerIdErr"]: status};
            state.valid = false;
            setAddPlayerState(state);
            return;
        }
        var playerId = parseInt(addPlayerState.playerId);
        if (isNaN(playerId) || playerId <= 0) {
            status = "Invalid Player Id, not a number or less than 1.";
            var state = {...addPlayerState, ["playerIdErr"]: status};
            state.valid = false;
            setAddPlayerState(state);
            return;
        }
        var player = formState.players.find(it => it.id === playerId);
        if (player) {
            status = "Player is already added.";
            var state = {...addPlayerState, ["playerIdErr"]: status};
            state.valid = false;
            setAddPlayerState(state);
            return;
        }
        var state = {...addPlayerState, ["playerIdErr"]: status};
        state.valid = true;
        setAddPlayerState(state);
        setSaveStatus(1);
        AgentUtil.getPlayer(playerId).then(resp => {
            setSaveStatus(0);
            if (resp.success){
                var item = resp.item;
                if (item) {
                    if (item.operatorId !== formState.operatorId) {
                        status = `Player belongs to another operator [Player.OperatorId:${item.operatorId}].`;
                        var state = {...addPlayerState, ["playerIdErr"]: status};
                        state.valid = false;
                        setAddPlayerState(state);
                        return;
                    }
                    var fs = {...formState, ["updateNo"]: formState.updateNo + 1};
                    fs.updateNo = formState.updateNo + 1;
                    fs.players.push(item);
                    checkState(fs);
                    setFormState(fs);

                    var state = {...addPlayerState, ["playerIdErr"]: ""};
                    state.valid = true;
                    setAddPlayerState(state);
                    return;
                } 
                status = "Player not found.";
            } else {
                status = resp.message;
            }
            var state = {...addPlayerState, ["playerIdErr"]: status};
            state.valid = false;
            setAddPlayerState(state);
        }).catch(error => {
            setSaveError(`${METHOD_NAME} error: ${error}`);
            setSaveStatus(0);
        });
    };
    const onRemovePlayer = (item: Player) : void => {
        if (!formState.item) {
            //setSaveError("Agent is not saved.");
            setRemovePlayerState({
                playerId: item.id, 
                handled: false});
            return;
        }
        var agent : Agent = formState.item;
        var playerIndex = formState.players.findIndex(it => it.id === item.id);
        if (playerIndex < 0) {
            setSaveError("Cannot find agent player.");
            return;
        }
        var fs = {...formState, ["updateNo"]: formState.updateNo + 1};
        fs.players = formState.players.slice(0);
        var removedPlayer = fs.players.splice(playerIndex, 1);
        checkState(fs);
        setFormState(fs);
        setSaveError("");
    };
    const checkRemovePlayerState = () : void => {
        if (!removePlayerState.playerId || removePlayerState.playerId <= 0 || removePlayerState.handled) {
            return;
        }
        removePlayerState.handled = true;
        if (!formState.item) {
            setSaveError("Remove player failure: Agent is not saved.");
            return;
        }
        var agent : Agent = formState.item;
        var playerIndex = formState.players.findIndex(it => it.id === removePlayerState.playerId);
        if (playerIndex < 0) {
            setSaveError("Remove player failure: Cannot find agent player.");
            return;
        }
        var fs = {...formState, ["updateNo"]: formState.updateNo + 1};
        fs.players = formState.players.slice(0);
        var removedPlayer = fs.players.splice(playerIndex, 1);
        fs.name += "X";
        var fs = {...formState, ["name"]: "test"};
        checkState(fs);
        setFormState(fs);
        setSaveError("");
    }
    const onSave = () => {
        const METHOD_NAME = "SaveAgent";
        
        setSaveError("");
        setSaveStatus(0);
        if (!formState.valid) {
            setSaveError("Input is not valid");
            return;
        }
        if (!formState.update) {
            setSaveError("No changes");
            return;
        }
        setSaveStatus(1);
        var saveJson = JSON.stringify(formState.update);
        AgentUtil.saveAgent(formState.id, saveJson).then(resp => {
            setSaveStatus(0);
            if (resp.success){
                var item = resp.item;
                if (item) {
                    if (!formState.id || formState.id <= 0) {
                        history.push(`editAgent/${item.id}`);
                        return;
                    }
                    var fs = {...formState, id: item.id};
                    updateState(fs, item);
                    setFormState(fs);
                    updateAvatarImageInfo(fs);
                    return;
                } 
                setSaveError(`${METHOD_NAME} failure: Agent was not returned.`);

            } else {
                setSaveError(resp.message);
            }
        }).catch(error => {
            setSaveError(`${METHOD_NAME} error: ${error}`);
            setSaveStatus(0);
        });
           
    };

    var saveEnabled = false;
    var addPlayerEnabled = false;
    var agent = formState.item;
    if (formState.valid && formState.update && !refreshing && saveStatus === 0) {
        saveEnabled = true;
    }
    var initErrorBox = null;
    if (initErr && initErr.length > 0) {
        initErrorBox = (<Box marginTop={3} color="red">{initErr}</Box>);
        saveEnabled = false;
    }

    var playersErrorBox = null;
    if (formState.playersError && formState.playersError.length > 0) {
        playersErrorBox = (<Box marginTop={3} color="red">{formState.playersError}</Box>);
    }

    var saveErrorBox = null;
    if (saveError.length > 0) {
        saveErrorBox = (<Box color="red">{saveError}</Box>);
    }

    if (agent && formState.valid && !refreshing && saveStatus === 0 && addPlayerState.playerId && addPlayerState.playerId.length > 0) {
        addPlayerEnabled = true;
    }
    checkRemovePlayerState();

    var operatorInput = null;
    if (agent && agent.players && agent.players.length > 0) {
        operatorInput = (<Box marginTop={1}>
        <TextField
            label="Operator"
            name="operatorId"
            value={formState.operatorId}
            InputProps={{
                readOnly: true, disableUnderline: true
                }}
        /></Box>);

    } else {
        operatorInput = (
            <OperatorSelect value={formState.operatorId}
                onChange={onOperatorChange}
                filter={it => it.agentSyndicateActive}
                allItemEnabled={false}
                helperText={formState.operatorIdError}
                error={formState.operatorIdError.length > 0}
            />

        );
    }

    var avatarImg = null;
    if (agent) {
        var imageUrl = `agents/${agent.id}/avatarImage`;
        avatarImg = (
            <ImageView imageType="" imageUrl={imageUrl} label="Avatar image" changeImageUrl={imageUrl} removeImageUrl={imageUrl} disabled={saveEnabled} onChange={onAvatarImageChange} onRemove={onAvatarImageRemove}/>
        );
    }

    var title = agent ? `Edit Agent #${agent.id}` : "Create Agent";
    return (
        <Container maxWidth="xl">
            <Title title={title} />
            <Box>
                <TextField
                    label="Id"
                    name="id"
                    value={formState.id ? formState.id : "0"}
                    helperText={formState.item ? `CreatedAt: ${CreateRoundUtil.toGameDateString(formState.item.createdAt)}` : ""}
                    InputProps={{
                        readOnly: true, disableUnderline: true
                        }}
                />
            </Box>
            <Box marginTop={2}>
                <TextField
                    autoFocus
                    label="Name"
                    name="name"
                    value={formState.name}
                    onChange={e => handleChange("name", e.target.value)}
                    helperText={formState.nameError}
                    error={formState.nameError.length > 0}
                    style={{width:400}}
                />
            </Box>
            {operatorInput}
            <Box marginTop={1}>
                <TextField
                    label="Fee ratio"
                    name="feePercentage"
                    value={formState.feePercentage}
                    onChange={e => handleChange("feePercentage", e.target.value)}
                    helperText={formState.feePercentageError}
                    error={formState.feePercentageError.length > 0}
                />
            </Box>
            <Box marginTop={1}>
                <TextField
                    label="Currency"
                    name="currency"
                    value={formState.currency}
                    onChange={e => handleChange("currency", e.target.value)}
                    helperText={formState.currencyError}
                    error={formState.currencyError.length > 0}
                />
            </Box>
            <Box marginTop={2}>
                <TextField
                    label="Ord no"
                    name="ordNo"
                    value={formState.ordNo}
                    onChange={e => handleChange("ordNo", e.target.value)}
                    helperText={formState.ordNoError}
                    error={formState.ordNoError.length > 0}
                />
            </Box>
            <Box marginTop={2}>
                <TextField
                    label="Profile text"
                    name="profileText"
                    value={formState.profileText}
                    onChange={e => handleChange("profileText", e.target.value)}
                    helperText={formState.profileTextError}
                    error={formState.profileTextError.length > 0}
                    style={{width:400}}
                    variant="outlined"
                    multiline
                    maxRows={6}
                />
            </Box>
            <Box marginTop={2}>
                Avatar: {avatarImageInfo}<br/>
                {avatarImg}
                {/* <ImagePicker onChange={onAvatarImageChange} /> */}
            </Box>
            <Box marginTop={1} display="flex" alignItems="center">
                <TextField
                    label="Player Id"
                    name="playerId"
                    value={addPlayerState.playerId}
                    onChange={e => handleAddPlayerChange("playerId", e.target.value)}
                    helperText={addPlayerState.playerIdErr}
                    error={addPlayerState.playerIdErr.length > 0}
                />&nbsp;&nbsp;
                <Button onClick={e => onAddPlayer()} color="secondary" variant="contained" disabled={!addPlayerEnabled}>
                    Add Agent Admin
                </Button>
            </Box>
            <Box marginTop={1}>
                <Box>
                    Agent Admins
                </Box>
                <EditAgentPlayerTable items={formState.players} onDelete={onRemovePlayer} />
            </Box>
            {playersErrorBox} 
            {initErrorBox} 
            <Box marginTop={2}>
                <Button onClick={e => onSave()} color="primary" variant="contained" disabled={!saveEnabled}>
                    Save
                </Button>
            </Box>
            {saveErrorBox} 
            <Box>
            <Button
                    component={RouterLink}
                    color="secondary"
                    size="small"
                    style={{textTransform:"none"}}
                    to={"/agentOverview"}
                    >
                    Goto Agent Overview
                </Button>&nbsp;

            </Box>
            {/* <Box>
                <pre>{JSON.stringify(formState, undefined, 2)}</pre>

            </Box> */}
        </Container>
    );
};

export default EditAgent;
