import React, { useEffect, useState } from "react";
import { Box, Button } from "@material-ui/core";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { BaseItemState, OverviewCriteria } from "../Ticker2/Ticker2Models";
import FixtureOverviewUtil from "./FixtureOverviewUtil";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import RoundOverviewUtil from "../RoundOverview/RoundOverviewUtil";
import Ticker2Util from "../Ticker2/Ticker2Util";
import FixtureMappingTable from "./FixtureMappingTable";
import { ActionState, FixtureMapping } from "./FixtureOverviewModels";
import { Fixture } from "../TeamOverview/TeamOverviewModels";
import { League } from "../LeagueOverview/LeagueModels";
import { GenericResponse } from "../CreateRound/CreateRoundModels";
import { ListResponse } from "../api/ApiNgModels";
import { UpdateItemState } from "../CreateSyndicate/CreateSyndicateModels";
import { blue } from "@material-ui/core/colors";
import ConfirmationDialog from "../Components/ConfirmationDialog";

// interface FixtureMappingViewState {
//     leagueId: number;
//     leagueName: string;
// }
interface FixtureMappingItemState extends BaseItemState<FixtureMapping,number> {
    fixtureId: number;
    leagueId: number;
}
interface FixtureMappingViewProps {
    fixture?: Fixture;
    league?: League;
    criteria?: OverviewCriteria;
}
const FixtureMappingView: React.FC<FixtureMappingViewProps> = (props) => {
    var propsFixtureId = 0;
    var propsFixtureNme = "";
    if (props.fixture) {
        propsFixtureId = props.fixture.id;
        propsFixtureNme = props.fixture.name;
    } else if (props.league) {
        propsLeagueId = props.league.id;
    }
    var propsLeagueId = 0;
    if (props.fixture) {
        propsLeagueId = props.fixture.leagueId;
    } else if (props.league) {
        propsLeagueId = props.league.id;
    }
    var criteria = props.criteria ? props.criteria : {
        pageSize: 100,
        from:  CreateRoundUtil.getDateWithoutTime(new Date()),
        to: CreateRoundUtil.getDateWithoutTime(CreateRoundUtil.addDays(new Date(), 14)),
        createNo: 1
    };

    const [itemState, setItemState] = useState<FixtureMappingItemState>({
        fixtureId: propsFixtureId,
        leagueId: propsLeagueId,
        items: [],
        more: false,
        lastId: 0,
        refreshing: false

    });
    //const [actionError, setActionError] = useState<string>("");
    const [actionState, setActionState] = useState<ActionState>(FixtureOverviewUtil.createEmptyActionState());
    const [updateItemState, setUpdateItemState] = useState<UpdateItemState<FixtureMapping>>({
        method: 'none',
        item: undefined,
        handled: false,
        updateNo: 0,
        version: 0
    });
    const history = useHistory();   

    useEffect(() => {
        refreshItems("Init");
    }, []);

    const getFixtureMappings = async (lastId: number): Promise<GenericResponse<ListResponse<FixtureMapping>>> => {
        if (propsFixtureId <= 0) {
            var pageSize = criteria.pageSize;
            var from : Date = criteria.from;
            var to : Date = criteria.to;
            if (propsLeagueId > 0) {
                return await FixtureOverviewUtil.getFixtureMappingsByLeague(propsLeagueId, pageSize, from, to, lastId);
            }
            return await FixtureOverviewUtil.getFixtureMappings(pageSize, from, to, lastId, undefined);
        }
        return await FixtureOverviewUtil.getFixtureMappingsByFixture(propsFixtureId);
    };
    const refreshItems = (caller: string) => {
        if (itemState.refreshing) {
            return;
        }
        var ast = FixtureOverviewUtil.createEmptyActionState();
        var rs : FixtureMappingItemState = {
            fixtureId: propsFixtureId,
            leagueId: propsLeagueId,
            items: [],
            more: false,
            lastId: 0,
            refreshing: false
        }
        var lastId = caller === "onMore" ? itemState.lastId : 0;
        if (lastId > 0) {
            rs.items = itemState.items;
        }
        var success = false;
        itemState.refreshing = true;
        setActionState(FixtureOverviewUtil.createActionState(true, false, `RefreshItems(${caller})...`));
        getFixtureMappings(lastId).then( resp => {
            if (!resp.success) {
                //setActionError(`${caller} error: ${resp.message}`);
                ast.error = true;
                ast.message = `RefreshItems(${caller}) error: ${resp.message}`;
                return;
            }
            var listResponse = resp.item;
            if (listResponse) {
                if (listResponse.items) {
                    if (lastId > 0) {
                        for (const item of listResponse.items) {
                            rs.items.push(item);
                        }
                    } else {
                        rs.items = listResponse.items;
                    }
                    if (listResponse.items.length > 0) {
                        rs.lastId = listResponse.items[listResponse.items.length - 1].id;
                    }
                    if (listResponse.pageSize > 0 && listResponse.pageSize <= listResponse.items.length) {
                        rs.more = true;
                    }
                }           
            }
            success = true;
        }).catch( error => {
            //setActionError(CreateRoundUtil.getExceptionMessage(error, false, `${caller} error`));
            ast.error = true;
            ast.message = CreateRoundUtil.getExceptionMessage(error, false, `RefreshItems(${caller}) error`);
        }).finally( () => {
            if (success || lastId <= 0) {
                setItemState(rs);
            }
            itemState.refreshing = false;
            setActionState(ast);
        });
    };
    const onRefreshItems = () => {
        refreshItems("Refresh");
    };
    const onMore = () : void => {
        if (itemState.lastId <= 0) {
            //setActionError("More failure: LastId was not set");
            setActionState(FixtureOverviewUtil.createActionState(false, true, "More failure: LastId was not set."));
            return;
        }
        refreshItems("onMore");
    }
    const onDeleteItem = (item: FixtureMapping) => {
        if (!item) {
            setActionState(FixtureOverviewUtil.createActionState(false, true, "Fixture mapping is not specified."));
            return;
        }
        // if (item.mappingId && item.mappingId > 0) {
        //     setAction(FixtureOverviewUtil.createActionState(false, true, "Ext fixture is already mapped."));
        //     return;
        // }
        setUpdateItemState({
            method: 'remove',
            item: item,
            handled: false,
            updateNo: 0,
            version: 0
        });
    };
    const unmapFixture = (item: FixtureMapping) => {
        
        var ast = FixtureOverviewUtil.createEmptyActionState(); 
        setActionState(FixtureOverviewUtil.createActionState(true, false, "Unmapping fixture..."));
        var finished = false;
        FixtureOverviewUtil.unmapFixture(item.id).then((resp) => {
            if (!resp.success) {
                ast.error = true;
                ast.message = `Unmap Fixture error: ${resp.message}`;
                return;
            }
            var extFixtureIndex = itemState.items.findIndex(it => it.id === item.id);
            if (extFixtureIndex >= 0) {
                var ist = {...itemState, refreshing: false};
                ist.items.splice(extFixtureIndex, 1);
                setItemState(ist);
            }
        }).catch(error => {
            ast.error = true;
            ast.message = CreateRoundUtil.getExceptionMessage(error, false, "Unmap Fixture error");
        }).finally(() => {
            setActionState(ast);
        });
    };

    const onUnmapFixtureClose = (confirmed: boolean | undefined) : void => {
        if (updateItemState.handled) {
            return;
        }
        var ms = {...updateItemState, handled: true};
        setUpdateItemState(ms);
        if (!confirmed || !ms.item) {
            return;
        }
        unmapFixture(ms.item);      
    };    
    if (itemState.fixtureId !== propsFixtureId || itemState.leagueId !== propsLeagueId){
        itemState.fixtureId = propsFixtureId;
        itemState.leagueId = propsLeagueId;
        itemState.items = [];
        refreshItems("ChangeFixture");
    }
    // var leagueInfo = "League - Not selected";
    // if (props.fixture) {
    //     leagueInfo = `League - Id:${props.fixture.leagueId} | Name: ${props.fixture.leagueName}`;
    // } else if (props.league) {
    //     leagueInfo = `League - Id:${props.league.id} | Name: ${props.league.name}`;
    // }
    // var fixtureInfo = "Fixture - Not selected";
    // if (props.fixture) {
    //     fixtureInfo = `Fixture - Id:${props.fixture.id} | Name: ${props.fixture.name} | Starting at: ${CreateRoundUtil.toGameDateString(props.fixture.startingAt)} | Status: ${props.fixture.status}`;
    // }
    // var actionErrorBox = null;
    // if (actionError.length > 0) {
    //     actionErrorBox = (<Box color={RoundOverviewUtil.INVALID_COLOR} fontSize={10}>{actionError}</Box>)
    // }
    var isExecuting = itemState.refreshing || actionState.executing;
    var actionBox = null;
    if ((actionState.executing || actionState.error) && actionState.message.length > 0) {
        actionBox = (
            <Box color={actionState.error ? RoundOverviewUtil.INVALID_COLOR : blue[500]} fontSize={12}>
                {actionState.message}
            </Box>
        );
    }
    var info = Ticker2Util.toItemStateString(itemState);
    var moreBtn = null;
    if (itemState.more && !itemState.refreshing) {
        moreBtn = (<Button 
            variant="text"
            color="secondary"
            size="small"
            disabled={isExecuting}
            onClick={e => onMore()}>
            More
        </Button>);
    }

    var updateDialog = null;
    if (updateItemState.item && !updateItemState.handled) {
        if (updateItemState.method === "remove") {
            var mapItem = updateItemState.item;
            const unmapContent = (
                <Box>
                    Please confirm that you want to unmap fixture <br/>'<b>{mapItem.fixtureName} ({CreateRoundUtil.toGameDateString(mapItem.startingAt)})</b>' <br/>with external<br/>
                    '<b>{mapItem.extFixtureName}</b>'<br/>at provider<br/><b>{mapItem.providerId}</b><br/>with mapping id<br/><b>{mapItem.id}</b> .
                </Box>
            );
            updateDialog = (
                <ConfirmationDialog open={true} title="Unmap fixture" message="Please confirm that you want to unmap fixture." messageElement={unmapContent} onClose={onUnmapFixtureClose} />
            );            
        }
    }

    return (
        <div>
            {/* <Box fontSize={10}>
                {leagueInfo}
            </Box>
            <Box fontSize={10}>
                {fixtureInfo}
            </Box> */}
            {actionBox}
            <Box fontSize={10}>
                {info}{moreBtn}
            </Box>
            <FixtureMappingTable items={itemState.items} onDelete={onDeleteItem} />
            {updateDialog}
        </div>
    );
};

export default FixtureMappingView;

