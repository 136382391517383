import React, { useState } from "react";
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, MenuItem, Link } from '@material-ui/core';
import ImagePicker, { FileUploadInfo } from "./ImagePicker";
import { BaseResponse } from "../FixtureOverview/FixtureOverviewModels";
import ApiNgUtil from "../api/ApiNgUtil";

interface ImagePickerDialogProps {
    open: boolean;
    openLabel?: string;
    onClose: (info?: FileUploadInfo) => void;
    uploadUrl?: string;
}
interface ImagePickerDialogState {
    info?: FileUploadInfo;
}


const ImagePickerDialog: React.FC<ImagePickerDialogProps> = (props) => {

    const [open, setOpen] = useState<boolean>(false);
    const [formState, setFormState] = useState<ImagePickerDialogState>({});
    const [saveError, setSaveError] = useState<string>("");
    const [saveStatus, setSaveStatus] = useState<number>(0);
 
    const handleClickOpen = () => {
        setOpen(true);
        setFormState({});
    };

    const handleClose = () => {
        setOpen(false);
        if (props.onClose) {
            props.onClose(undefined);
        }
    };
    const onImageChange = (info: FileUploadInfo) =>{
        setFormState({ 
            info: info
        });
    }

    const onOk = () => {
        const methodName = "OnOK";
        setSaveError("");
        if (!formState.info || !formState.info.valid) {
            setSaveError("Image is not specified or is not valid.");
            return;
        }
        if (props.uploadUrl && props.uploadUrl.length > 0 && formState.info.file) {
            setSaveStatus(1);
            var error = "";
            ApiNgUtil.uploadImage<BaseResponse>(props.uploadUrl, formState.info.file).then(resp => {
                if (!resp.success) {
                    error = `UploadImage error: ${resp.message}`;
                    return;
                }
                var innerResp = resp.item;
                if (innerResp) {
                    if (!innerResp.success) {
                        error = `UploadImage (inner) error: ${innerResp.message}`;
                        return;                            
                    }
                }
            }).catch(ex => {
                error = `UploadImage exception: ${ex}`;
            }).finally(() => {
                setSaveStatus(0);
                setSaveError(error);
                if (error.length <= 0) {
                    setOpen(false);
                    if (props.onClose) {
                        props.onClose(formState.info);
                    }                       
                }
            });
            return;
        }
        setOpen(false);
        if (props.onClose) {
            props.onClose(formState.info);
        }           
    };

    var saveEnabled = (formState.info && formState.info.valid && saveStatus === 0);
    var saveErrorBox = null;
    if (saveError.length > 0) {
        saveErrorBox = (<Box color="red">{saveError}</Box>);
    }
    var openLabel = props.openLabel && props.openLabel.length > 0 ? props.openLabel : "Select image";
    var openButton = (<Button onClick={handleClickOpen} variant="outlined" color="secondary" size="small">
                    {openLabel}
            </Button>);
    var title = (props.uploadUrl && props.uploadUrl.length > 0) ? "Select/Upload image" : "Select image";
    return (
        <div>
            {openButton}
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent dividers>
                <ImagePicker onChange={onImageChange} />
                {saveErrorBox} 
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={onOk} color="primary" disabled={!saveEnabled}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
};

export default ImagePickerDialog;
