import React, { useEffect, useState } from "react";
import { Button, TextField, Box, MenuItem } from '@material-ui/core';
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import { FileUploadInfo } from "./ImagePicker";
import ImagePickerDialog from "./ImagePickerDialog";
import ApiNgUtil from "../api/ApiNgUtil";
import { ActionState, BaseResponse } from "../FixtureOverview/FixtureOverviewModels";
import FixtureOverviewUtil from "../FixtureOverview/FixtureOverviewUtil";
import RoundOverviewUtil from "../RoundOverview/RoundOverviewUtil";
import { blue } from "@material-ui/core/colors";
import { UpdateItemState } from "../CreateSyndicate/CreateSyndicateModels";
import ConfirmationDialog from "./ConfirmationDialog";

interface ImageViewProps {
    label: string;
    imageUrl: string;
    imageType: string;
    changeImageUrl? : string;
    removeImageUrl? : string;
    disabled?: boolean;
    onChange?: (info: FileUploadInfo) => void;
    onRemove?: () => void;
}
interface ImageViewState {
    info?: FileUploadInfo;
    updateNo: number;
}

interface ImageDataState {
    dataUrl: string;
    error: boolean;
    message: string;
}
interface RemoveImageState extends UpdateItemState<string> {
}
const ImageView: React.FC<ImageViewProps> = (props) => {
    
    const [formState, setFormState] = useState<ImageViewState>({
        updateNo: 0
    });
    const [imageData, setImageData] = useState<string>("");
    const [actionState, setActionState] = useState<ActionState>(FixtureOverviewUtil.createEmptyActionState());
    const [removeImageState, setRemoveImageState] = useState<RemoveImageState>({
        method: 'none',
        item: undefined,
        handled: false,
        updateNo: 0,
        version: 0,
    });

    useEffect(() => {
        var imageD = "";
        var ast = FixtureOverviewUtil.createEmptyActionState();
        if (props.imageUrl && props.imageUrl.length > 0) {
            setActionState(FixtureOverviewUtil.createActionState(true, false, `GetImage from api...`));
            ApiNgUtil.getImage(props.imageUrl).then(resp => {
                if (!resp.success) {
                    ast.error = true;
                    ast.message = `GetImage from api error: ${resp.message}`;
                    return;
                }
                var blob = resp.item;
                if (blob) {
                    imageD = URL.createObjectURL(blob);
                }
            }).catch(error => {
                ast.error = true;
                ast.message = CreateRoundUtil.getExceptionMessage(error, false, `GetImage from api exception`);
            }).finally(() => {
                setImageData(imageD);
                setActionState(ast);
            });
            return;
        }
        try {
            if (formState.info?.file) {
                imageD = URL.createObjectURL(formState.info.file);
            }
        } catch (error) {
            ast.error = true;
            ast.message = CreateRoundUtil.getExceptionMessage(error, false, `GetImage from info exception`);
        } finally {
            setImageData(imageD);
            setActionState(ast);
        }
    }, [formState.updateNo, props.imageUrl]);
    
    const onClosePickerDlg = (info?: FileUploadInfo) => {
        if (!info) {
            return;
        }
        var fs = {...formState, info: info};
        fs.updateNo++;
        setFormState(fs);
        if (props.onChange) {
            props.onChange(info);
        }
    };
    const onRemove = () => {
        if (!imageData || imageData.length <= 0) {
            return;
        }
        if (props.imageUrl && props.imageUrl.length > 0 && props.removeImageUrl && props.removeImageUrl.length > 0) {
            setRemoveImageState({
                method: 'remove',
                item: props.removeImageUrl,
                handled: false,
                updateNo: 0,
                version: 0,
            });
            return;
        }
        var fs = {...formState, info: undefined};
        fs.updateNo++;
        setFormState(fs);
        setImageData("");
        if (props.onRemove) {
            props.onRemove();
        }
    };
    const onRemoveImpl = () => {
        if (!imageData || imageData.length <= 0) {
            return;
        }
        if (props.imageUrl && props.imageUrl.length > 0 && props.removeImageUrl && props.removeImageUrl.length > 0) {
            var ast = FixtureOverviewUtil.createEmptyActionState();
            setActionState(FixtureOverviewUtil.createActionState(true, false, `RemoveImage...`));
            ApiNgUtil.deleteObject<BaseResponse>(props.removeImageUrl).then(resp => {
                if (!resp.success) {
                    ast.error = true;
                    ast.message = `RemoveImage error: ${resp.message}`;
                    return;
                }
                var innerResp = resp.item;
                if (innerResp) {
                    if (!innerResp.success) {
                        ast.error = true;
                        ast.message = `RemoveImage (inner) error: ${innerResp.message}`;
                        return;                            
                    }
                }
            }).catch(ex => {
                ast.error = true;
                ast.message = `RemoveImage exception: ${ex}`;
            }).finally(() => {
                setActionState(ast);
                if (!ast.error) {
                    var fs = {...formState, info: undefined};
                    fs.updateNo++;
                    setFormState(fs);
                    setImageData("");
                    if (props.onRemove) {
                        props.onRemove();
                    }
                }
            });
            return;
        }
        var fs = {...formState, info: undefined};
        fs.updateNo++;
        setFormState(fs);
        setImageData("");
        if (props.onRemove) {
            props.onRemove();
        }
    };
    const onConfirmRemoveClose = (confirmed: boolean | undefined) : void => {
        if (removeImageState.handled) {
            return;
        }
        var ms = {...removeImageState, handled: true};
        setRemoveImageState(ms);
        if (!confirmed || !ms.item) {
            return;
        }
        onRemoveImpl();      
    };

    var imagePickerDlg = null;
    var removeBtn = null;
    if (props.disabled === undefined || props.disabled === false) {
        imagePickerDlg = (<ImagePickerDialog open={false} openLabel="Change" onClose={onClosePickerDlg} uploadUrl={props.changeImageUrl} />);
        if (imageData && imageData.length > 0) {
            removeBtn = (
                <Button onClick={e => onRemove()} variant="outlined" color="secondary" size="small" style={{marginLeft:"4px"}}>Remove</Button>
            );
        }
    }
    var img = null;
    var imgBox = null;
    if (imageData && imageData.length > 0) {
        img = (
            <img src={imageData} />
        );
        imgBox = (
            <Box>{img}</Box>
        );
    } else {
        imgBox = (
            <Box>No image</Box>
        );
    }
    var actionBox = null;
    if ((actionState.executing || actionState.error) && actionState.message.length > 0) {
        actionBox = (
            <Box color={actionState.error ? RoundOverviewUtil.INVALID_COLOR : blue[500]} fontSize={12}>
                {actionState.message}
            </Box>
        );
    }

    var confirmRemoveDialog = null;
    if (removeImageState.item && !removeImageState.handled) {
        if (removeImageState.method === "remove") {
            var mapItem = removeImageState.item;
            confirmRemoveDialog = (
                <ConfirmationDialog open={true} title="Remove" message="Are you sure you want to remove image?" onClose={onConfirmRemoveClose} />
            );            
        }

    }
    
    return (
        <>
        {imgBox}
        <Box display="flex" alignItems="center">
            {imagePickerDlg}{removeBtn}
        </Box>
        {confirmRemoveDialog}
        {actionBox}
        </>
    );
};

export default ImageView;
