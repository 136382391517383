import React, { useEffect, useState } from "react";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { Container, Tab, Tabs, Box, Typography, Button, TextField } from "@material-ui/core";
import AgentOverviewTable from "./AgentOverviewTable";
import { Title } from "react-admin";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import { AgentOverviewFilter, AgentOverviewItem } from "./AgentModels";
import { QueryParams } from "../models/CouponShare";
import { TabPanel } from "../Components/TabPanel";
import AgentUtil from "./AgentUtil";
import RoundOverviewUtil from "../RoundOverview/RoundOverviewUtil";
import { GenericResponse } from "../CreateRound/CreateRoundModels";
import { ListResponse } from "../api/ApiNgModels";
import SyndicateView from "./SyndicateView";
import { BaseItemState, OverviewCriteria } from "../Ticker2/Ticker2Models";
import Ticker2Util from "../Ticker2/Ticker2Util";
import AgentPlayerView from "./AgentPlayerView";
import AgentDetailsView from "./AgentDetailsView";

interface AgentOverviewState {
    operatorId: string;
    q: string;
    syndicatesRequired: boolean;
    fromInput: string;
    toInput: string;
    limitInput: string;
    from?: Date;
    to?: Date;
    limit?: number;
    valid: boolean;
    fromError: string;
    toError: string;
    limitError: string;
}
interface AgentOverviewItemState extends BaseItemState<AgentOverviewItem, number> {
}
function checkFormState(fs: AgentOverviewState)  {
    var valid = true;
    var from = CreateRoundUtil.parseDateX(fs.fromInput);
    var to = CreateRoundUtil.parseDateX(fs.toInput);
    var limit = parseInt(fs.limitInput);
    var dateTime = from;
    var fromErr = "";
    var toErr = "";
    var limitErr = "";
    if (fs.fromInput.length > 0) {
        var dateNumber = from.getDate();
        if (isNaN(dateNumber)) {
            valid = false;
            fromErr = "Invalid date";
        } else {
            fs.from = from;
        }
    }
    else {
        valid = false;
        fromErr = "Required";
    }
    if (fs.toInput.length > 0) {
        var dateNumber = to.getDate();
        if (isNaN(dateNumber)) {
            valid = false;
            fromErr = "Invalid date";
        } else {
            fs.to = to;
        }
    }
    else {
        valid = false;
        fromErr = "Required";
    }
    if (fs.limitInput.length > 0) {
        if (isNaN(limit)) {
            valid = false;
            limitErr = "Invalid integer";
        } else if (limit < 1 || limit > 100) {
            valid = false;
            limitErr = "Integer in range 1-100";
        } else {
            fs.limit = limit;
        }
    }
    else {
        valid = false;
        limitErr = "Required";
    }

    fs.valid = valid;
    fs.fromError = fromErr;
    fs.toError = toErr;
    fs.limitError = limitErr;
}

function getInitialState() : AgentOverviewState {
    var s = sessionStorage.getItem("AgentOverviewState");
    var fs : AgentOverviewState | undefined = undefined;
    if (s && s.length > 0) {
        try {
            var fs1: AgentOverviewState = JSON.parse(s);
            if (fs1) {
                checkFormState(fs1);
                if (fs1.valid) {
                    fs = fs1;
                }
            }
        } catch {

        }
    }
    if (!fs) {
        fs = {
            operatorId: "",
            q: "",
            syndicatesRequired: false,
            fromInput: CreateRoundUtil.getDateWithoutTimeString(CreateRoundUtil.addDays(new Date(), -7)),
            toInput: CreateRoundUtil.getDateWithoutTimeString(CreateRoundUtil.addDays(new Date(), 30)),
            limitInput: "25",
            from: CreateRoundUtil.getDateWithoutTime(CreateRoundUtil.addDays(new Date(), -7)),
            to: CreateRoundUtil.getDateWithoutTime(CreateRoundUtil.addDays(new Date(), 30)),
            limit: 25,
            valid: true,
            fromError: "",
            toError: "",
            limitError: ""
            };
    }
    return fs;
};

var initialState = getInitialState();

function createCriteria(state: AgentOverviewState, createNo: number) : OverviewCriteria {
    var fs = state;
    return {
        pageSize: fs.limit ? fs.limit : 25,
        from:  fs.from ? fs.from : CreateRoundUtil.getDateWithoutTime(CreateRoundUtil.addDays(new Date(), -7)),
        to: fs.to ? fs.to : CreateRoundUtil.getDateWithoutTime(CreateRoundUtil.addDays(new Date(), 30)),
        createNo: createNo
    }
};
function getInitialCriteria() : OverviewCriteria {
    return createCriteria(initialState, 1);
};
var initialCriteria = getInitialCriteria();


const AgentOverview: React.FC = () => {
    const [formState, setFormState] = useState<AgentOverviewState>(initialState);
    const [criteria, setCriteria] = useState<OverviewCriteria>(initialCriteria);
    const [itemState, setItemState] = useState<AgentOverviewItemState>({
        items: [],
        more: false,
        lastId: 0,
        refreshing: false
    });

    const [actionError, setActionError] = useState<string>("");
    const [selectedItem, setSelectedItem] = useState<AgentOverviewItem | undefined>(undefined);
    const [selectedTab, setSelectedTab] = useState(() => 0)
    const history = useHistory();


    useEffect(() => {
        search("Init", formState);
    }, []);

    const handleChange = (name: string, value:string) =>{
        var fs = {...formState, [name]: value};
        checkFormState(fs);
        setFormState(fs);
    }

    const toQueryParams = (state: AgentOverviewState) : QueryParams => {
        const page = 0;
        const pageSize = state.limit ? state.limit : 25;
        var operatorId : string | undefined = state.operatorId.length === 0 ? undefined : state.operatorId;
        var q : string | undefined = state.q.length === 0 ? undefined : state.q;
        var params : QueryParams = {
            pagination: { page: page, perPage: pageSize },
            filter: { operatorId: operatorId, q: q, requireSyndicates:state.syndicatesRequired },
            //sort: { field: state.orderBy, order: state.orderDirection }
        }
        return params;
    };
    const toFilter = (state: AgentOverviewState) : AgentOverviewFilter | undefined => {
        var count = 0;
        var operatorId : string | undefined = undefined;
        if (state.operatorId.length > 0) {
            operatorId = state.operatorId;
            count++;
        }
        var q : string | undefined = undefined;
        if (state.q.length > 0) {
            q = state.q;
            count++;
        }
        var syndicatesRequired : boolean | undefined = undefined;
        if (state.syndicatesRequired) {
            syndicatesRequired = state.syndicatesRequired;
            count++;
        }
        if (count <= 0) {
            return undefined;
        }
        return {
            operatorId: operatorId,
            q: q,
            syndicatesRequired: syndicatesRequired
        }
    };
    const search = (caller: string, fs: AgentOverviewState) : void => {
        setActionError("");
        var filter = toFilter(fs);
        var crit : OverviewCriteria = {
            pageSize: formState.limit ? formState.limit : criteria.pageSize,
            from: formState.from ? formState.from : criteria.from,
            to: formState.to ? formState.to : criteria.to,
            createNo: criteria.createNo + 1
        };
        if (caller === "Search") {
            initialState = formState;
            initialCriteria = crit;    
        }
        setCriteria(crit);
        var rs : AgentOverviewItemState = {
            items:[],
            more: false,
            lastId: 0,
            refreshing: false
        };
        itemState.refreshing = true;
        AgentUtil.getAgentOverviews(crit.pageSize, crit.from, crit.to, 0, filter).then((resp) => {
            if (!resp.success) {
                setActionError(`${caller} error: ${resp.message}`);
                return;
            }
            var listResponse = resp.item;
            if (listResponse) {
                if (listResponse.items) {
                    rs.items = listResponse.items;
                    if (listResponse.items.length > 0) {
                        rs.lastId = listResponse.items[listResponse.items.length - 1].id;
                    }
                    if (listResponse.pageSize <= listResponse.items.length) {
                        rs.more = true;
                    }
                }           
            }
        }).catch(error => {
            setActionError(CreateRoundUtil.getExceptionMessage(error, false, `${caller} error`));
        }).finally( () => {
            itemState.refreshing = false;
            setItemState(rs);
        });
    };

    const onSearch = () : void => {
        search("Search", formState);
        sessionStorage.setItem("AgentOverviewState", JSON.stringify(formState));
    };

    const getMore = async (fs: AgentOverviewState) : Promise<GenericResponse<ListResponse<AgentOverviewItem>>> => {
        const METHOD_NAME = "getMore";
        try {
            var filter = toFilter(fs);
            const lastId = itemState ? itemState.lastId : 0;
            if (lastId <= 0) {
                return CreateRoundUtil.createGenericResponse<ListResponse<AgentOverviewItem>>(false, "LastId not specified.", null);
            }
            return AgentUtil.getAgentOverviews(criteria.pageSize, criteria.from, criteria.to, lastId, filter)

        } catch (error) {
            console.error(`${METHOD_NAME} error.`, error);
            var message = CreateRoundUtil.getExceptionMessage(error, false, METHOD_NAME + " error");
            return CreateRoundUtil.createGenericResponse<ListResponse<AgentOverviewItem>>(false, message, null);
        }
    }
    const onMore = () : void => {
        if (!itemState || itemState.lastId <= 0) {
            setActionError("More failure: LastId was not set");
            return;
        }
        setActionError("");
        var ts : AgentOverviewItemState = {
            items: itemState ? itemState.items : [],
            more: false,
            lastId: 0,
            refreshing: false
        }
        if (itemState) {
            itemState.refreshing = true;
        }
        getMore(formState).then(resp => {
            if (!resp.success) {
                setActionError(`More.getMore error: ${resp.message}`);
                return;
            }
            var listResponse = resp.item;
            if (listResponse) {
                if (listResponse.items) {
                    for (const item of listResponse.items) {
                        ts.items.push(item);
                    }               
                    if (ts.items.length > 0) {
                        ts.lastId = ts.items[ts.items.length - 1].id;
                    }
                    if (listResponse.pageSize <= listResponse.items.length) {
                        ts.more = true;
                    }
                }           
            }
            setItemState(ts);
        }).catch(error => {
            setActionError(CreateRoundUtil.getExceptionMessage(error, false, "More.getMore error (2)"));
        }).finally(() => {
            itemState.refreshing = false;
        });
    }

    const onSelect = (item: AgentOverviewItem) => {
        setSelectedItem(item);
    };
    const onDeselect = () => {
        setSelectedItem(undefined);
    };
    const onCreateSyndicate = () => {
        history.push('createSyndicate');
    };
    var actionErrorBox = null;
    if (actionError.length > 0) {
        actionErrorBox = (<Box color={RoundOverviewUtil.INVALID_COLOR} fontSize={10}>{actionError}</Box>)
    }
    var summaryBox = null;
    var items = itemState.items;
    var info = Ticker2Util.toItemStateString(itemState, criteria);
    var count = items.length;
    var playerCount = 0;
    var syndicateCount = 0;
    var shareCount = 0;
    var totalShareCount = 0;
    items.forEach(r => {
        if (r.nPlayers) {
            playerCount += r.nPlayers;
        }
        if (r.nSyndicates) {
            syndicateCount += r.nSyndicates;
        }
        if (r.nShares) {
            shareCount += r.nShares;
        }
        if (r.maxShares) {
            totalShareCount += r.maxShares;
        }
    }); 
    var summaryText = `Summary - Count: ${count} | Players: ${playerCount} | Syndicates: ${syndicateCount} | Shares: ${shareCount} of ${totalShareCount}`;
    summaryBox = (<Box fontSize={12}>{summaryText}</Box>)

    var moreBtn = null;
    if (itemState.more && !itemState.refreshing) {
        moreBtn = (<Button 
            variant="text"
            color="secondary"
            size="small"
            disabled={!formState.valid || itemState.refreshing}
            onClick={e => onMore()}>
            More
        </Button>);
    }

    var agentInfoBox = null;
    if (selectedItem && selectedItem.id > 0) {
        var agentInfoText = `Agent: ${selectedItem.name} | Operator: ${selectedItem.operatorId}`;
        agentInfoBox = (<Box fontSize={12}>{agentInfoText}</Box>);
    }
    return (
        <Container maxWidth="xl">
            <Title title="Agent Overview" />
            <Box paddingBottom={1}>
                <Button 
                    component={RouterLink}
                    variant="contained"
                    color="secondary"
                    size="small"
                    to={"/editAgent"}>
                    Create agent
                </Button>
                &nbsp;&nbsp;
                 <Button 
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={e => onCreateSyndicate()}>
                    Create syndicate
                </Button>
                &nbsp;&nbsp;
                <TextField
                    type="date"
                    name="from"
                    value={formState.fromInput}
                    label="From"
                    InputLabelProps={{shrink: true}}
                    error={formState.fromError && formState.fromError.length > 0 ? true : false}
                    helperText={formState.fromError}
                    onChange={e => handleChange("fromInput", e.target.value)}
                />&nbsp;
                <TextField
                    type="date"
                    name="to"
                    value={formState.toInput}
                    label="To"
                    InputLabelProps={{shrink: true}}
                    error={formState.toError && formState.toError.length > 0 ? true : false}
                    helperText={formState.toError}
                    onChange={e => handleChange("toInput", e.target.value)}
                />&nbsp;
                <TextField
                    name="limit"
                    label="Page size"
                    type="number"
                    value={formState.limitInput}
                    style={{ width: 100 }}
                    InputLabelProps={{shrink: true}}
                    error={formState.limitError && formState.limitError.length ? true : false}
                    helperText={formState.limitError}
                    onChange={e => handleChange("limitInput", e.target.value)}
                />&nbsp;
                <Button 
                    variant="contained"
                    color="secondary"
                    size="small"
                    disabled={!formState.valid}
                    onClick={e => onSearch()}>
                    Search
                </Button>&nbsp;
            </Box>
            {actionErrorBox}
            <Box fontSize={10}>
                {info}{moreBtn}
            </Box>
            {summaryBox}
            <AgentOverviewTable items={items} onSelect={onSelect} onDeselect={onDeselect} />
            {agentInfoBox}
            <div>
                <Tabs value={selectedTab}
                    onChange={(ev, value) => { setSelectedTab(value) }}
                >
                    <Tab label="Details" />
                    <Tab label="Syndicates" />
                    <Tab label="Agent admins" />
                </Tabs>
                <TabPanel value={selectedTab} index={0}>
                    <AgentDetailsView agent={selectedItem} />
                </TabPanel>
                <TabPanel value={selectedTab} index={1}>
                    <SyndicateView agent={selectedItem} criteria={criteria} />
                </TabPanel>
                <TabPanel value={selectedTab} index={2}>
                    <AgentPlayerView agent={selectedItem} criteria={criteria} />
                </TabPanel>
            </div>

        </Container>
    );
};

export default AgentOverview;
