import React, { useState } from 'react';
import { Button, Link, Switch, TableCell, TableRow, Theme, createStyles, makeStyles } from '@material-ui/core';
import CreateRoundUtil from '../CreateRound/CreateRoundUtil';
import { useHistory, Link as RouterLink } from "react-router-dom";
import { League } from './LeagueModels';
import { GenericResponse } from '../CreateRound/CreateRoundModels';
import ApiNgUtil from '../api/ApiNgUtil';
import LeagueUtil from './LeagueUtil';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRow: {
      "&:hover":{
        cursor: "pointer"
      }
    }
  }),
);

interface LeagueRowState {
  updating : boolean;
  enabledUpdate : number;
}
interface LeagueRowProps {
  league: League;
  selected: boolean;
  onSelect?: (item?: League) => void;
}
const LeagueRow: React.FC<LeagueRowProps> = (props) => {
  const classes = useStyles();
 
  const [leagueState, setLeagueState] = useState<LeagueRowState>({
    updating:false,
    enabledUpdate: 0
  });
  const history = useHistory();

  const onSelect = () => {
    if (props.onSelect) {
      if (props.selected) {
        props.onSelect(undefined);
      } else {
        props.onSelect(props.league);
      }
      return;
    }
    //alert("LeagueRow.onSelect property not set.");
  };
//   const createUpdateRoundResponse = (success: boolean, message: string, league : Leauge | null) : UpdateRoundResponse => {
//     return {
//       success: success,
//       message: message,
//       league: league
//     };
//   }

  const endUpdating = () => {
    setLeagueState({
      updating: false,
      enabledUpdate: 0
    });
  };  

  // const updateRound = (saveRoundJson: string, caller: string, action : (success: boolean, message : string) => void) => {
  const updateLeague = async (updateJson: string, caller: string) : Promise<GenericResponse<League>> => {
    const methodName = "UpdateLeague." + caller;
    try {

        var respX = await LeagueUtil.updateLeague(props.league.id, updateJson);
        return respX;
    } catch (error){
      console.error(methodName + " error!", error);
      return CreateRoundUtil.createGenericResponse<League>(false, methodName + " error: " + error, null);

    }
  }
  

  const onEnabledChange = () => {
    setLeagueState({
      updating: true,
      enabledUpdate:  props.league.enabled ? -1 : 1
    });
    let updateJson = JSON.stringify({
      enabled: !props.league.enabled
    });
    updateLeague(updateJson, "onEnabledChange").then(resp => {
      if (resp.success){
        if (resp.item){
          props.league.enabled = resp.item.enabled;
        //   if (props.onEnabledChange) {
        //     props.onEnabledChange(props.league);
        //   }
        }
      } else {
        alert(resp.message);
      }
      //setUpdateRoundResp(resp);
      endUpdating();
    });
  };
  
 

  var row = props.league;
  var enabledSwitch = null;
  var leagueEnabled = row.enabled;
  if (leagueState.updating){
    switch (leagueState.enabledUpdate) {
      case 1:
        leagueEnabled = true;
        break;
      case -1:
        leagueEnabled = false;
        break;
    }
  }

  return (
    <TableRow 
        key={row.id} 
        hover
        onClick={(e: any) => onSelect()}
        selected={props.selected}
        className={classes.tableRow}
    >
      <TableCell  align='right' component="th" scope="row">
        <Link component={RouterLink} to={"leagues/" + row.id}>{row.id}</Link>
      </TableCell>
      <TableCell>
        {row.name}
      </TableCell>
      <TableCell>
        {row.title}
      </TableCell>
      <TableCell>
        {CreateRoundUtil.toGameDateString(row.createdAt)}
      </TableCell>
      <TableCell>
        {row.currentSeasonName}
      </TableCell>
       <TableCell >
        <Switch
            checked={leagueEnabled}
            onChange={(e: any) => onEnabledChange()}
            inputProps={{ 'aria-label': 'controlled' }}
            disabled={leagueState.updating}
            size="small"
          />
       </TableCell>
       <TableCell align='right'>
        {row.nFixtures}
       </TableCell>
       <TableCell align='right'>
        {row.nMappings}
       </TableCell>
       <TableCell>
        {row.externalId}
      </TableCell>
    </TableRow>    
  );
 
};

export default LeagueRow;
