import React, { useEffect, useState } from "react";
import { Box, Button, Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { AgentOverviewItem } from "./AgentModels";
import ImageView from "../Components/ImageView";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";

interface AgentDetailsViewProps {
    agent?: AgentOverviewItem;
}


const AgentDetailsView: React.FC<AgentDetailsViewProps> = (props) => {  
    const [avatarImageInfo, setAvatarImageInfo] = useState<string>("");

    const updateAvatarImageInfo = (agent?: AgentOverviewItem) => {
        if (agent && agent.avatarImageSize && agent.avatarImageSize > 0) {
            setAvatarImageInfo(`File type: ${agent.avatarImageType} | Size:  ${agent.avatarImageSize}`);
        } else {
            setAvatarImageInfo("");
        }
    }
    useEffect(() => {
        updateAvatarImageInfo(props.agent);
    }, [props.agent]);


    if (!props.agent) {
        return (
            <div>
                No agent selected.
            </div>
        )
    }
    var agent : AgentOverviewItem = props.agent;
    var imageUrl = `agents/${agent.id}/avatarImage`;
    var avatarImg = (
        <ImageView imageType="" imageUrl={imageUrl} label="Avatar image" disabled={true} />
    );
    const textBoxStyles = {
        border: "1px solid black", 
        width: "fit-content",
        maxWidth: "5000px",
        minWidth: "200px",
        minHeight: "50px",
        height: "fit-content",
        marginTop: "10px",
        marginLeft: "10p",
        background: "white",
        padding: "2px"
    };

    return (
        <div>
            {/* <Box>
                <TextField
                    label="Id"
                    name="id"
                    value={agent.id ? agent.id : "0"}
                    helperText={`CreatedAt: ${CreateRoundUtil.toGameDateString(agent.createdAt)}`}
                    InputProps={{
                        readOnly: true, disableUnderline: true
                        }}
                />
            </Box>
            <Box marginTop={2}>
                <TextField
                    label="Name"
                    name="name"
                    value={formState.name}
                    onChange={e => handleChange("name", e.target.value)}
                    helperText={formState.nameError}
                    error={formState.nameError.length > 0}
                    style={{width:400}}
                />
            </Box>
            {operatorInput}
            <Box marginTop={1}>
                <TextField
                    label="Fee ratio"
                    name="feePercentage"
                    value={formState.feePercentage}
                    onChange={e => handleChange("feePercentage", e.target.value)}
                    helperText={formState.feePercentageError}
                    error={formState.feePercentageError.length > 0}
                />
            </Box>
            <Box marginTop={1}>
                <TextField
                    label="Currency"
                    name="currency"
                    value={formState.currency}
                    onChange={e => handleChange("currency", e.target.value)}
                    helperText={formState.currencyError}
                    error={formState.currencyError.length > 0}
                />
            </Box>
            <Box marginTop={2}>
                <TextField
                    label="Ord no"
                    name="ordNo"
                    value={formState.ordNo}
                    onChange={e => handleChange("ordNo", e.target.value)}
                    helperText={formState.ordNoError}
                    error={formState.ordNoError.length > 0}
                />
            </Box> */}
            <Box marginTop={2}>
                Profile text{agent.profileText ? ` (size: ${agent.profileText.length})` : null}:
             </Box>
             <div style={textBoxStyles}
                //border={4}
                // borderLeft={0}
                // borderRight={0}
                //borderColor="primary.main"
                >
                <span style={{ whiteSpace: "pre-line"}}>{agent.profileText}</span>
                
             </div>
            <Box marginTop={2}>
                Avatar: {avatarImageInfo}<br/>
                {avatarImg}
            </Box>
        </div>
    );
};

export default AgentDetailsView;

