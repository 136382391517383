import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from "react-router-dom";
import { Link } from '@material-ui/core';
import DataTable, { DataTableColumn, Order, useDataTableStyles } from '../Components/DataTable';
import { AgentOverviewItem, SyndicateOverviewItem } from './AgentModels';
import CreateRoundUtil from '../CreateRound/CreateRoundUtil';
import { friendlyName } from '../common/helpers/RoundHelper';
import { formatNumber, formatToCurrency } from '../common/helpers/CurrencyHelpers';

interface SyndicateTableProps {
    agent?: AgentOverviewItem;
    items: SyndicateOverviewItem[];
    onSelect? : (item : SyndicateOverviewItem) => void;
}

const SyndicateTable: React.FC<SyndicateTableProps> = (props) => {
    const classes = useDataTableStyles();
    const [selectedId, setSelectedId] = useState<number>(0);
    const [columns, setColumns] = useState<DataTableColumn<SyndicateOverviewItem>[]>([]);
    const [agentColumns, setAgentColumns] = useState<DataTableColumn<SyndicateOverviewItem>[]>([]);

    useEffect(() => {
      refreshColumns();
    }, []);
    
    const refreshColumns = () => {
      var agentNameColumn : DataTableColumn<SyndicateOverviewItem> = { id: 'agentName', numeric: false, disablePadding: false, label: 'Agent', filterEnabled: true, visible: false };
      var operatorIdColumn : DataTableColumn<SyndicateOverviewItem> = { id: 'operatorId', numeric: false, disablePadding: false, label: 'Operator', filterEnabled: true, visible: false };
      var cols: DataTableColumn<SyndicateOverviewItem>[] = [
        { id: 'id', numeric: false, disablePadding: true, label: "Id", filterEnabled: true, renderValue: (item: SyndicateOverviewItem) => { return (<Link component={RouterLink} to={`editSyndicate/${item.id}`}>{item.id}</Link>); } },
        { id: 'name', numeric: false, disablePadding: false, label: 'Syndicate', filterEnabled: true },
        { id: 'status', numeric: false, disablePadding: false, label: 'Status', filterEnabled: true },
        { id: 'roundId', numeric: true, disablePadding: false, label: 'Round', filterEnabled: true, renderValue: (item: SyndicateOverviewItem) =>{ return (<Link component={RouterLink} to={`rounds/${item.roundId}`}>{item.roundId}</Link>); } },
        { id: 'roundTypeName', numeric: false, disablePadding: false, label: 'Type', filterEnabled: true, renderValue: (row: SyndicateOverviewItem) => friendlyName(row.roundTypeName) },
        { id: 'deadline', numeric: false, disablePadding: false, label: 'Deadline', renderValue: (item: SyndicateOverviewItem) => CreateRoundUtil.toGameDateString(item.deadline)},
        agentNameColumn,
        operatorIdColumn,
        { id: 'playerId', numeric: true, disablePadding: false, label: 'Player', filterEnabled: true },
        { id: 'couponId', numeric: true, disablePadding: false, label: 'Coupon', filterEnabled: true, renderValue: (item: SyndicateOverviewItem) =>{ return (<Link component={RouterLink} to={`coupons/${item.couponId}`}>{item.couponId}</Link>); } },
        { id: 'createdAt', numeric: false, disablePadding: false, label: 'Created at', renderValue: (item: SyndicateOverviewItem) => CreateRoundUtil.toGameDateString(item.createdAt)},
        { id: 'agentFeePercentage', numeric: true, disablePadding: false, label: 'Agent fee ratio', renderValue: (item: SyndicateOverviewItem) => formatNumber(item.agentFeePercentage, 4) },
        { id: 'shareAmount', numeric: true, disablePadding: false, label: 'Share amount', renderValue: (item: SyndicateOverviewItem) => formatToCurrency(item.shareAmount, item.shareCurrency) },
        { id: 'nShares', numeric: true, disablePadding: false, label: '#Shares' },
        { id: 'maxShares', numeric: true, disablePadding: false, label: '#Max shares' },
        { id: 'shareCurrency', numeric: false, disablePadding: false, label: 'Share currency', filterEnabled: true, visible:false }
      ];
      setColumns(cols);
      setAgentColumns([agentNameColumn, operatorIdColumn]);
    }
    const onSelect = (item : SyndicateOverviewItem) => {
        setSelectedId(item.id);
        if (props.onSelect){
          props.onSelect(item);
          return;
      }
    };
  
    const agentSelected : boolean = props.agent ? true : false;
    for (var agentColumn of agentColumns) {
      agentColumn.visible = !agentSelected;
    }
  return (
    <DataTable columns={columns} items={props.items} getId={it => it.id} orderBy='id' order='desc' resultFilterEnabled={true} size='xsmall' onSelect={onSelect} rowsPerPage={25}  />
    );
};
  
export default SyndicateTable;
