import { GenericResponse } from "../CreateRound/CreateRoundModels";
import ApiNgUtil from "../api/ApiNgUtil";
import { ListResponse } from "../api/ApiNgModels";
import InvokeApiMethods from "../ExternalApi/ExternalApiModels";
import AgentUtil from "../AgentOverview/AgentUtil";
import { Fixture, FixtureFilter } from "../TeamOverview/TeamOverviewModels";
import TeamOverviewUtil from "../TeamOverview/TeamOverviewUtil";
import { ExtFixture, ExtFixtureItem, FixtureMapping, ActionState, MapFixtureRequest, FixtureMappingFilter, BaseResponse } from "./FixtureOverviewModels";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import LeagueUtil from "../LeagueOverview/LeagueUtil";

class FixtureOverviewUtil {
    static async getFixtures(pageSize: number, from: Date| undefined, to: Date| undefined, lastId: number, filter?: FixtureFilter) : Promise<GenericResponse<ListResponse<Fixture>>> {
        var urlParams = AgentUtil.getUrlParams(pageSize, from, to, lastId, filter);
        var url = "fixtures?" + urlParams;
        var response = await ApiNgUtil.getListByUrl<Fixture>(url, "fixtures");
        if (!response.item?.items) {
            return response;
        }
        for (var item of response.item.items) {
            item.score = `${item.homeTeam?.score}-${item.awayTeam?.score}`;
            item.shirtsOrder = TeamOverviewUtil.getFixtureShirtsOrder(item);
        }
        return response;
    }
    static async getFixtureMappingsByFixture(fixtureId: number) : Promise<GenericResponse<ListResponse<FixtureMapping>>> {
        var url = `fixtures/${fixtureId}/mappings`;
        var response = await ApiNgUtil.getListByUrl<FixtureMapping>(url, "fixtureMappings");
        return response;
    }
    static async getFixtureMappingsByLeague(leagueId: number, pageSize: number, from: Date| undefined, to: Date| undefined, lastId: number) : Promise<GenericResponse<ListResponse<FixtureMapping>>> {
        var urlParams = AgentUtil.getUrlParams(pageSize, from, to, lastId, undefined);
        var url = `leagues/${leagueId}/fixtureMappings?${urlParams}`;
        var response = await ApiNgUtil.getListByUrl<FixtureMapping>(url, "fixtureMappings");
        return response;
    }
    static async getFixtureMappings(pageSize: number, from: Date| undefined, to: Date| undefined, lastId: number, filter?: FixtureMappingFilter) : Promise<GenericResponse<ListResponse<FixtureMapping>>> {
        var urlParams = AgentUtil.getUrlParams(pageSize, from, to, lastId, filter);
        var url = `fixtures/mappings?${urlParams}`;
        var response = await ApiNgUtil.getListByUrl<FixtureMapping>(url, "fixtureMappings");
        return response;
    }
    static async getExtFixtures(providerId: string, leagueId: number) : Promise<GenericResponse<ListResponse<ExtFixture>>> {
        var url = `extFixtures?providerId=${providerId}&leagueId=${leagueId}`;
        var listResponse = await ApiNgUtil.getListByUrl<ExtFixture>(url, "extFixtures");
        return listResponse;
        // if (!listResponse.success) {
        //     return listResponse;
        // }
        // var items : ExtFixture[] = [];
        // if (listResponse.item?.items) {
        //     items = listResponse.item?.items;
        // }
        // if (items) {
        //     for (var i = 0; i < items.length; i++) {
        //         var item = items[i];
        //         var mapping = item.mapping;
        //         if (mapping) {
        //             item.mappingId = mapping.id;
        //             item.fixtureId = mapping.fixtureId;
        //             item.fixtureName = mapping.fixtureName;
        //             item.leagueId = mapping.leagueId;
        //             item.leagueName = mapping.leagueName;
        //             item.fixtureStartingAt = mapping.startingAt;
        //             item.fixtureStatus = mapping.fixtureStatus;
        //         }
        //     }
        // }
        // return listResponse;
    }

    static async getExtFixtureItems(providerId: string, leagueId: number) : Promise<GenericResponse<ListResponse<ExtFixtureItem>>> {
        var resp = await FixtureOverviewUtil.getExtFixtures(providerId, leagueId);
        if (!resp.success) {
            return { success: false, message: resp.message, item: null };
        }
        var listResp = resp.item;
        if (!listResp) {
            return { success: true, message: resp.message, item: null };
        }
        var items : ExtFixtureItem[] = [];
        if (listResp.items) {
            items = FixtureOverviewUtil.toExtFixtureItems(listResp.items);
        }
        var newListResp : ListResponse<ExtFixtureItem> = {
            success: listResp.success,
            message: listResp.message,
            items: items,
            page: listResp.page,
            pageSize: listResp.pageSize,
            size: listResp.size,
            totalPages: listResp.totalPages,
            totalSize: listResp.totalPages
        };
        return { success: true, message: resp.message, item: newListResp };
    }
    static toExtFixtureItem(extFixture: ExtFixture) : ExtFixtureItem {
        var item : ExtFixtureItem = {
            extFixture: extFixture,
            id: extFixture.id,
            name: extFixture.name,
            startingAt: extFixture.startingAt,
            extStatus: extFixture.extStatus,
            status: extFixture.status,
            competitionId: extFixture.competitionId,
            idPath: extFixture.idPath,
            namePath: extFixture.namePath
        };
        var mapping = extFixture.mapping;
        if (mapping) {
            item.mappingId = mapping.id;
            item.fixtureId = mapping.fixtureId;
            item.fixtureName = mapping.fixtureName;
            item.fixtureStartingAt = mapping.startingAt;
            item.fixtureStatus = mapping.fixtureStatus;
            item.leagueId = mapping.leagueId;
            item.leagueName = mapping.leagueName;
            item.mappingStatus = mapping.status;
            item.mappingReversed = mapping.reversed;
            var leagueNameParts = LeagueUtil.getLeagueNameParts(mapping.leagueName);
            item.leagueTitle = (leagueNameParts) ? leagueNameParts.title : mapping.leagueName;
        }
        return item;
    }
    static toExtFixtureItems(extFixtures: ExtFixture[]) : ExtFixtureItem[] {
        if (!extFixtures) {
            return [];
        }
        var items : ExtFixtureItem[] = [];
        for (const extFixture of extFixtures) {
            var item = FixtureOverviewUtil.toExtFixtureItem(extFixture);
            items.push(item);
        }
        return items;
    }
    static async mapExtFixture(request: MapFixtureRequest) : Promise<GenericResponse<FixtureMapping>> {
        const METHOD_NAME = "FixtureOverviewUtil.mapExtFixture";
        var url = `fixtures/${request.fixtureId}/map`;
        var saveJson = JSON.stringify(request);
        try {
            var resp = await ApiNgUtil.getObjectWithBody<FixtureMapping>(url, InvokeApiMethods.POST(), saveJson);
            return resp;
        } catch (error) {
            console.error(METHOD_NAME + " error.", error);
            return CreateRoundUtil.createGenericResponse<FixtureMapping>(false, METHOD_NAME + " error: " + error, null);
        }
    }
    static async unmapFixture(mappingId: number) : Promise<GenericResponse<boolean>> {
        const METHOD_NAME = "FixtureOverviewUtil.unmapFixture";
        var url = `fixtures/mappings/${mappingId}`;
        try {
            var resp = await ApiNgUtil.getObjectWithMethod<BaseResponse>(url, InvokeApiMethods.DELETE());
            if (resp.success) {
                if (resp.item) {
                    const success : boolean = (resp.item.success && resp.item.success === true);
                    return CreateRoundUtil.createGenericResponse(success, resp.item.message, success);
                } 
                return CreateRoundUtil.createGenericResponse(false, "No response", false);
            }
            return CreateRoundUtil.createGenericResponse(false, resp.message, false);
        } catch (error) {
            console.error(METHOD_NAME + " error.", error);
            return CreateRoundUtil.createGenericResponse<boolean>(false, METHOD_NAME + " error: " + error, false);
        }
    }
    static createEmptyActionState() : ActionState {
        return { executing: false, error: false, message: "" };
    }
    static createActionState(running: boolean, error: boolean, message: string) : ActionState {
        return { executing: running, error: error, message: message };
    }
}
export default FixtureOverviewUtil;