import React, { useEffect, useState } from 'react';
import DataTable, { DataTableColumn } from '../Components/DataTable';
import CreateRoundUtil from '../CreateRound/CreateRoundUtil';
import { LeagueMapping } from './LeagueModels';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

interface LeagueMappingTableProps {
    items: LeagueMapping[];
    onSelect? : (item : LeagueMapping) => void;
    onDelete?: (item: LeagueMapping)  => void;
}

const LeagueMappingTable: React.FC<LeagueMappingTableProps> = (props) => {
    const [selectedId, setSelectedId] = useState<number>(0);
    const [columns, setColumns] = useState<DataTableColumn<LeagueMapping>[]>([]);
 
    useEffect(() => {
      refreshColumns();
    }, []);
    
    const refreshColumns = () => {
      var cols: DataTableColumn<LeagueMapping>[] = [
        { id: undefined, numeric: false, disablePadding: true, label: "Action", filterEnabled: false, renderValue: it => renderAction(it) },
        { id: 'id', numeric: false, disablePadding: true, label: "Mapping Id", filterEnabled: true },
        { id: 'leagueTitle', numeric: false, disablePadding: false, label: 'League', filterEnabled: true },
        { id: 'providerId', numeric: false, disablePadding: false, label: 'Provider' },
        { id: 'extCompetitionId', numeric: false, disablePadding: false, label: 'Ext Id' },
        { id: 'extCompetitionName', numeric: false, disablePadding: false, label: 'Ext name' },
        { id: 'createdAt', numeric: false, disablePadding: false, label: 'Created at', renderValue: (item: LeagueMapping) => { return CreateRoundUtil.toGameDateString(item.createdAt); } },
      ];
      setColumns(cols);
    }
    const renderAction = (item : LeagueMapping) : any => {
      return (
        <IconButton aria-label="delete" color="secondary" size="small" onClick={(e: any) => onDelete(item)} disabled={!props.onDelete} >
          <DeleteIcon />
        </IconButton>
      );
    };    
    const onSelect = (item : LeagueMapping) => {
        setSelectedId(item.id);
        if (props.onSelect){
          props.onSelect(item);
          return;
      }
    };
    const onDelete = (item : LeagueMapping) => {
      if (props.onDelete){
        props.onDelete(item);
      }
    }
    
    return (
      <DataTable columns={columns} items={props.items} getId={it => it.id} orderBy='leagueTitle' order='asc' resultFilterEnabled={true} size='xsmall' onSelect={onSelect}  />
    );
  };
  
  export default LeagueMappingTable;
 