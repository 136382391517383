import React, { useEffect, useState } from 'react';
import DataTable, { DataTableColumn } from '../Components/DataTable';
import { ExtLeague } from './LeagueModels';
import RoundOverviewUtil from '../RoundOverview/RoundOverviewUtil';
import { yellow } from '@material-ui/core/colors';
import { Box, Button, Link } from '@material-ui/core';

interface ExtLeagueTableProps {
    items: ExtLeague[];
    onSelect? : (item : ExtLeague) => void;
    onMap? : (item : ExtLeague) => void;
}

const ExtLeagueTable: React.FC<ExtLeagueTableProps> = (props) => {
    const [selectedId, setSelectedId] = useState<string>("");
    const [columns, setColumns] = useState<DataTableColumn<ExtLeague>[]>([]);
 
    useEffect(() => {
      refreshColumns();
    }, []);
    
    const refreshColumns = () => {
      var cols: DataTableColumn<ExtLeague>[] = [
        { id: 'id', numeric: false, disablePadding: true, label: "Ext Id", filterEnabled: true },
        { id: 'name', numeric: false, disablePadding: false, label: 'Ext name', filterEnabled: true },
        { id: 'countryName', numeric: false, disablePadding: false, label: 'Country', filterEnabled: true },
        { id: 'mappingId', numeric: false, disablePadding: false, label: 'Mapping Id', filterEnabled: true },
        { id: 'mappingStatus', numeric: false, disablePadding: false, label: 'Mapping Status', renderValue: it => renderMappingStatus(it) },
        { id: 'leagueId', numeric: false, disablePadding: false, label: 'League Id' },
        { id: 'leagueName', numeric: false, disablePadding: false, label: 'League Name' },
      ];
      setColumns(cols);
    }
    const renderMappingStatus = (item : ExtLeague) : any => {
      var statusName = item.mappingStatus;
      var color : string | undefined = undefined;
      if (item.mappingId && item.mappingId > 0) {
        color = RoundOverviewUtil.VALID_COLOR;
      } else if (statusName) {
        switch (statusName) {
          case "EXACT_MATCH":
            color = yellow[500];
            break;
          default:
            color = RoundOverviewUtil.INVALID_COLOR;
            break;
        }
      }
      if (color) {
        var content : any = null;
        if (item.leagueId && props.onMap) {
          content = (<Link component={Button} onClick={e => onMap(item)}>{statusName}</Link>);
        } else {
          content = statusName;
        }
        return (
          <Box bgcolor={color}>{content}</Box>
        );
      }
      return statusName ? statusName : "";
    };
    const onSelect = (item : ExtLeague) => {
        setSelectedId(item.id);
        if (props.onSelect){
          props.onSelect(item);
      }
    };
    const onMap = (item : ExtLeague) => {
      if (props.onMap){
        props.onMap(item);
      }
    };
    return (
      <DataTable columns={columns} items={props.items} getId={it => it.id} orderBy='name' order='asc' resultFilterEnabled={true} size='xsmall' onSelect={onSelect}  />
    );
  };
  
  export default ExtLeagueTable;
 