import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { LeagueMapping } from "./LeagueModels";
import LeagueUtil from "./LeagueUtil";
import LeagueMappingTable from "./LeagueMappingTable";
import { GenericResponse } from "../CreateRound/CreateRoundModels";
import { ListResponse } from "../api/ApiNgModels";
import { BaseItemState } from "../Ticker2/Ticker2Models";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import FixtureOverviewUtil from "../FixtureOverview/FixtureOverviewUtil";
import { ActionState } from "../FixtureOverview/FixtureOverviewModels";
import { UpdateItemState } from "../CreateSyndicate/CreateSyndicateModels";
import ConfirmationDialog from "../Components/ConfirmationDialog";

// interface LeagueMappingViewState {
//     leagueId: number;
//     leagueName: string;
//     mappings: LeagueMapping[];
// }
interface LeagueMappingItemState extends BaseItemState<LeagueMapping,number> {
    leagueId: number;
}
interface LeagueMappingViewProps {
    leagueId: number;
    leagueName: string;
}
const LeagueMappingView: React.FC<LeagueMappingViewProps> = (props) => {
    // const [formState, setFormState] = useState<LeagueMappingViewState>({
    //     leagueId: 0,
    //     leagueName: "",
    //     mappings: [],
    // });
    const [itemState, setItemState] = useState<LeagueMappingItemState>({
        leagueId: props.leagueId,
        items: [],
        more: false,
        lastId: 0,
        refreshing: false

    });
    //const [actionError, setActionError] = useState<string>("");
    const [actionState, setActionState] = useState<ActionState>(FixtureOverviewUtil.createEmptyActionState());
    const [updateItemState, setUpdateItemState] = useState<UpdateItemState<LeagueMapping>>({
        method: 'none',
        item: undefined,
        handled: false,
        updateNo: 0,
        version: 0
    });

    useEffect(() => {
        refreshItems("Init");

    }, []);

    const getLeagueMappings = async (lastId: number): Promise<GenericResponse<ListResponse<LeagueMapping>>> => {
        if (props.leagueId <= 0) {
            var pageSize = 100;
            var from : Date | undefined = undefined;
            var to : Date | undefined = undefined;
            return await LeagueUtil.getLeagueMappings(pageSize, from, to, lastId, undefined);

        }
        return await LeagueUtil.getLeagueMappingsByLeagueId(props.leagueId);
    };
    const refreshItems = (caller: string) => {
        if (itemState.refreshing) {
            return;
        }
        var ast = FixtureOverviewUtil.createEmptyActionState();
        var rs : LeagueMappingItemState = {
            leagueId: props.leagueId,
            items: [],
            more: false,
            lastId: 0,
            refreshing: false
        }
        var lastId = caller === "onMore" ? itemState.lastId : 0;
        if (lastId > 0) {
            rs.items = itemState.items;
        }
        var success = false;
        itemState.refreshing = true;
        setActionState(FixtureOverviewUtil.createActionState(true, false, `RefreshItems(${caller})...`));
        getLeagueMappings(lastId).then( resp => {
            if (!resp.success) {
                //setActionError(`${caller} error: ${resp.message}`);
                ast.error = true;
                ast.message = `RefreshItems(${caller}) error: ${resp.message}`;
                return;
            }
            var listResponse = resp.item;
            if (listResponse) {
                if (listResponse.items) {
                    if (lastId > 0) {
                        for (const item of listResponse.items) {
                            rs.items.push(item);
                        }
                    } else {
                        rs.items = listResponse.items;
                    }
                    if (listResponse.items.length > 0) {
                        rs.lastId = listResponse.items[listResponse.items.length - 1].id;
                    }
                    if (listResponse.pageSize > 0 && listResponse.pageSize <= listResponse.items.length) {
                        rs.more = true;
                    }
                }           
            }
            success = true;
        }).catch( error => {
            //setActionError(CreateRoundUtil.getExceptionMessage(error, false, `${caller} error`));
            ast.error = true;
            ast.message = CreateRoundUtil.getExceptionMessage(error, false, `RefreshItems(${caller}) error`);
        }).finally( () => {
            if (success || lastId <= 0) {
                setItemState(rs);
            }
            itemState.refreshing = false;
            setActionState(ast);
        });
    };
    const onRefreshItems = () => {
        refreshItems("Refresh");
    };
    const onMore = () : void => {
        if (itemState.lastId <= 0) {
            //setActionError("More failure: LastId was not set");
            setActionState(FixtureOverviewUtil.createActionState(false, true, "More failure: LastId was not set."));
            return;
        }
        refreshItems("onMore");
    }

    const onDeleteItem = (item: LeagueMapping) => {
        if (!item) {
            setActionState(FixtureOverviewUtil.createActionState(false, true, "League mapping is not specified."));
            return;
        }
        setUpdateItemState({
            method: 'remove',
            item: item,
            handled: false,
            updateNo: 0,
            version: 0
        });
    };
    const unmapLeague = (item: LeagueMapping) => {
        
        var ast = FixtureOverviewUtil.createEmptyActionState(); 
        setActionState(FixtureOverviewUtil.createActionState(true, false, "Unmapping league..."));
        var finished = false;
        LeagueUtil.unmapLeague(item.id).then((resp) => {
            if (!resp.success) {
                ast.error = true;
                ast.message = `Unmap League error: ${resp.message}`;
                return;
            }
            var extLeagueIndex = itemState.items.findIndex(it => it.id === item.id);
            if (extLeagueIndex >= 0) {
                var ist = {...itemState, refreshing: false};
                ist.items.splice(extLeagueIndex, 1);
                setItemState(ist);
            }
        }).catch(error => {
            ast.error = true;
            ast.message = CreateRoundUtil.getExceptionMessage(error, false, "Unmap League error");
        }).finally(() => {
            setActionState(ast);
        });
    };

    const onUnmapLeagueClose = (confirmed: boolean | undefined) : void => {
        if (updateItemState.handled) {
            return;
        }
        var ms = {...updateItemState, handled: true};
        setUpdateItemState(ms);
        if (!confirmed || !ms.item) {
            return;
        }
        unmapLeague(ms.item);      
    };    

    if (itemState.leagueId !== props.leagueId){
        itemState.leagueId = props.leagueId;
        itemState.items = [];
        refreshItems("ChangeLeague");
    }
    // var mappings = formState.mappings;
    // if (formState.leagueId !== props.leagueId){
    //     formState.leagueId = props.leagueId;
    //     formState.mappings = [];
    //     mappings = [];
    //     refreshMappings();
    // }
    // var leagueInfo = `League: ${props.leagueName}`;

    var updateDialog = null;
    if (updateItemState.item && !updateItemState.handled) {
        if (updateItemState.method === "remove") {
            var mapItem = updateItemState.item;
            const unmapContent = (
                <Box>
                    Please confirm that you want to unmap league <br/>'<b>{mapItem.leagueTitle}</b>' <br/>with external<br/>
                    '<b>{mapItem.extCompetitionName}</b>'<br/>at provider<br/><b>{mapItem.providerId}</b><br/>with mapping id<br/><b>{mapItem.id}</b> .
                </Box>
            );
            updateDialog = (
                <ConfirmationDialog open={true} title="Unmap league" message="Please confirm that you want to unmap league." messageElement={unmapContent} onClose={onUnmapLeagueClose} />
            );            
        }
    }

    return (
        <div>
            {/* <Box fontSize={10}>
                {leagueInfo}
            </Box> */}
            <LeagueMappingTable items={itemState.items} onDelete={onDeleteItem} />
            {updateDialog}
        </div>
    );
};

export default LeagueMappingView;

