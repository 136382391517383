import React, { useEffect, useState } from "react";
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, MenuItem, FormControlLabel, Switch } from '@material-ui/core';

interface ConfirmationDialogProps {
    open: boolean;
    title: string;
    message: string;
    messageElement?: JSX.Element;
    onClose: (confirmed: boolean | undefined) => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (props) => {    
    const [open, setOpen] = useState<boolean>(props.open);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleDialogClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) : void => {
        e.stopPropagation();
    }; 

    const handleClose = () => {
        setOpen(false);
        if (props.onClose) {
            props.onClose(undefined);
        }
    };
    const handleOk = () => {
        setOpen(false);
        if (props.onClose) {
            props.onClose(true);
        }
    };
    var messageContent = props.messageElement;
    if (!messageContent) {
        messageContent = (
            <DialogContentText>{props.message}</DialogContentText>
        );
    }
    return (
        <div>
        <Dialog
            open={open}
            onClose={handleClose}
            onClick={e => handleDialogClick(e)}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
            <DialogContent dividers>
                {messageContent}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleOk} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
};

export default ConfirmationDialog;
