import ApiNgUtil from "../api/ApiNgUtil";
import { ListResponse } from "../api/ApiNgModels";
import { formatToCurrency } from "../common/helpers/CurrencyHelpers";
import { Agent, Player, AgentOverviewFilter, AgentOverviewItem, AgentPlayerOverviewItem, SyndicateOverviewItem, PlayerId } from "./AgentModels";
import queryString from "query-string";
import { GenericResponse } from "../CreateRound/CreateRoundModels";
import { getListWithId, getObject } from "../api/dataProvider";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import InvokeApiMethods from "../ExternalApi/ExternalApiModels";
import fetchApi from "../../fetchApi";

class AgentUtil {
    static SYNDICATE_STATUS_DRAFT = "DRAFT";
    static SYNDICATE_STATUS_SUBMITTED = "SUBMITTED";
    static SYNDICATE_STATUS_PAUSED = "PAUSED";

    static getUrlParams(pageSize: number, from: Date | undefined, to: Date | undefined, lastId: any, filter?: any): string {
        var urlParams = `limit=${pageSize}&lastId=${lastId}`;
        if (from) {
            urlParams += `&from=${from.toISOString()}`
        }
        if (to) {
            urlParams += `&to=${to.toISOString()}`
        }
        if (filter) {
            const query = Object.assign({
                filter: JSON.stringify(filter)
            });
            urlParams += `&${queryString.stringify(query)}`;
        }
        return urlParams;
    }
    static async getAgentOverviews(pageSize: number, from: Date| undefined, to: Date| undefined, lastId: number, filter?: AgentOverviewFilter) : Promise<GenericResponse<ListResponse<AgentOverviewItem>>> {
        var urlParams = AgentUtil.getUrlParams(pageSize, from, to, lastId, filter);
        var url = "agents/overviews?" + urlParams;
        var listResponse = await ApiNgUtil.getListByUrl<AgentOverviewItem>(url, "agentOverviews");
        AgentUtil.fixAgentOverviewItems(listResponse.item?.items);
        return listResponse;
    }
    private static fixAgentOverviewItems(items?: AgentOverviewItem[]) {
        if (!items) {
            return;
        }
        for (var item of items) {
            if (item.profileText !== undefined && item.profileText !== null) {
                item.profileTextSize = item.profileText.length;
            }
        }
    }
    static async getSyndicateOverviews(agentId: number, pageSize: number, from: Date| undefined, to: Date| undefined, lastId: number) : Promise<GenericResponse<ListResponse<SyndicateOverviewItem>>> {
        var urlParams = AgentUtil.getUrlParams(pageSize, from, to, lastId, undefined);
        var url = `agents/${agentId}/syndicateoverviews?${urlParams}`;
        var listResponse = await ApiNgUtil.getListByUrl<SyndicateOverviewItem>(url, "syndicateOverviews");
        return listResponse;
    }
    static async getAgentPlayerOverviews(agentId: number, pageSize: number, from: Date| undefined, to: Date| undefined, lastId: number) : Promise<GenericResponse<ListResponse<AgentPlayerOverviewItem>>> {
        var urlParams = AgentUtil.getUrlParams(pageSize, from, to, lastId, undefined);
        var url = `agents/${agentId}/playeroverviews?${urlParams}`;
        var listResponse = await ApiNgUtil.getListByUrl<AgentPlayerOverviewItem>(url, "agentPlayerOverviews");
        return listResponse;
    }
    static compareAgentOverviewItemByOperatorAgent(x: AgentOverviewItem, y: AgentOverviewItem) : number {
        var comp = x.operatorId.localeCompare(y.operatorId);
        if (comp !== 0) {
            return comp;
        }
        return x.name.localeCompare(y.name);
    }
    static async getAgent(id: number) : Promise<GenericResponse<Agent>> {
        const METHOD_NAME = "AgentUtil.getAgent";
        try {
            var url = `agents/${id}`;
            var resp = await ApiNgUtil.getObject<Agent>(url);
            return resp;
        } catch (error) {
            console.error(METHOD_NAME + " error.", error);
            return CreateRoundUtil.createGenericResponse<Agent>(false, METHOD_NAME + " error: " + error, null);
        }
     }
     static async getSyndicate(id: number) : Promise<GenericResponse<SyndicateOverviewItem>> {
        const METHOD_NAME = "AgentUtil.getSyndicate";
        try {
            var url = `syndicates/${id}`;
            var resp = await ApiNgUtil.getObject<SyndicateOverviewItem>(url);
            return resp;
        } catch (error) {
            console.error(METHOD_NAME + " error.", error);
            return CreateRoundUtil.createGenericResponse<SyndicateOverviewItem>(false, METHOD_NAME + " error: " + error, null);
        }
     }
    static async getPlayersWithId(ids: number[]) : Promise<GenericResponse<Player[]>> {
        const METHOD_NAME = "CreateRoundUtil.getPlayersWithId";
        try {
            // var resp = await getListWithId("players", ids);
            // var players: Player[] = resp.data;
            // return CreateRoundUtil.createGenericResponse<Player[]>(true, "", players);
            //It doesn't work get list of players, so get them 1 by 1.
            var players: Player[] = [];
            for (var id of ids) {
                var resp = await AgentUtil.getPlayer(id);
                if (!resp.success) {
                    return CreateRoundUtil.createGenericResponse<Player[]>(false, `GetPlayer error [Id:${id}]: ${resp.message}`, players);
                }
                if (resp.item) {
                    players.push(resp.item);
                }
            }
            return CreateRoundUtil.createGenericResponse<Player[]>(true, "", players);
        } catch (error) {
            console.error(METHOD_NAME + " error.", error);
            return CreateRoundUtil.createGenericResponse<Player[]>(false, METHOD_NAME + " error: " + error, null);
        }
    }
    static async getPlayer(id: number) : Promise<GenericResponse<Player>> {
        const METHOD_NAME = "CreateRoundUtil.getPlayer";
        try {
            var resourceUrl = `players/${id}`;
            var resp = await getObject(resourceUrl);
            var player: Player = resp.data;
            if (!player) {
                return CreateRoundUtil.createGenericResponse<Player>(false, "Player not found", null);
            }
            return CreateRoundUtil.createGenericResponse<Player>(true, "", player);
        } catch (error) {
            console.error(METHOD_NAME + " error.", error);
            return CreateRoundUtil.createGenericResponse<Player>(false, METHOD_NAME + " error: " + error, null);
        }
    }
    static async saveAgent(id: number, saveJson: string) : Promise<GenericResponse<Agent>> {
        const METHOD_NAME = "CreateRoundUtil.saveAgent";
        var url = "agents";
        var method = InvokeApiMethods.POST();
        if (id && id > 0) {
            url += `/${id}`;
            method = InvokeApiMethods.PATCH();
        }
        try {
            var resp = await ApiNgUtil.getObjectWithBody<Agent>(url, method, saveJson);
            return resp;
            // var respX = await fetchApi(url, {
            //     method: method,
            //     body: saveJson
            // });
            // var resp = respX.json;
            // if (!resp) {
            //     return CreateRoundUtil.createGenericResponse<Agent>(false, `No response [Status:${respX.status}] [Body:${respX.body}]`, null);
            // }
            // var id: string = resp.id;
            // if (!id) {
            //     return CreateRoundUtil.createGenericResponse<Agent>(false, "'id' property is missing in response", null);
            // }
            // var agent : Agent = resp;
            // return CreateRoundUtil.createGenericResponse<Agent>(true, "", agent);
        } catch (error) {
            console.error(METHOD_NAME + " error.", error);
            return CreateRoundUtil.createGenericResponse<Agent>(false, METHOD_NAME + " error: " + error, null);
        }
    }
    static async updateSyndicate(id: number, saveJson: string) : Promise<GenericResponse<SyndicateOverviewItem>> {
        const METHOD_NAME = "CreateRoundUtil.updateSyndicate";
        if (!id || id <= 0) {
            return CreateRoundUtil.createGenericResponse<SyndicateOverviewItem>(false, "Id not specified", null);
        }
        var url = `syndicates/${id}`;
        var method = InvokeApiMethods.PATCH();
        try {
            var resp = await ApiNgUtil.getObjectWithBody<SyndicateOverviewItem>(url, method, saveJson);
            return resp;
        } catch (error) {
            console.error(METHOD_NAME + " error.", error);
            return CreateRoundUtil.createGenericResponse<SyndicateOverviewItem>(false, METHOD_NAME + " error: " + error, null);
        }
    }
}
export default AgentUtil;