import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from "react-router-dom";
import { Link } from '@material-ui/core';
import DataTable, { DataTableColumn, Order, useDataTableStyles } from '../Components/DataTable';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { AgentOverviewItem } from './AgentModels';
import CreateRoundUtil from '../CreateRound/CreateRoundUtil';
import { formatNumber } from '../common/helpers/CurrencyHelpers';

interface AgentOverviewTableProps {
    items: AgentOverviewItem[];
    onSelect? : (item : AgentOverviewItem) => void;
    onDeselect? : () => void;
}

const AgentOverviewTable: React.FC<AgentOverviewTableProps> = (props) => {
    const classes = useDataTableStyles();
    const [selectedId, setSelectedId] = useState<number>(0);
    const [columns, setColumns] = useState<DataTableColumn<AgentOverviewItem>[]>([]);

    useEffect(() => {
      refreshColumns();
    }, []);
    
    const refreshColumns = () => {
      var cols: DataTableColumn<AgentOverviewItem>[] = [
        // { id: 'id', numeric: false, disablePadding: true, label: "Agent Id", renderValue: (item: AgentOverviewItem) => { return (<Link component={RouterLink} to={`agents/${item.id}`}>{item.id}</Link>); }},
        { id: 'id', numeric: false, disablePadding: true, label: "Agent Id", renderValue: (item: AgentOverviewItem) => { return (<Link component={RouterLink} to={`editAgent/${item.id}`}>{item.id}</Link>); }},
        { id: 'name', numeric: false, disablePadding: false, label: 'Agent' },
        { id: 'operatorId', numeric: false, disablePadding: false, label: 'Operator' },
        { id: 'createdAt', numeric: false, disablePadding: false, label: 'Created at', renderValue: (item: AgentOverviewItem) => CreateRoundUtil.toGameDateString(item.createdAt) },
        { id: 'feePercentage', numeric: true, disablePadding: false, label: 'Fee ratio', renderValue: (item: AgentOverviewItem) => formatNumber(item.feePercentage, 4) },
        { id: 'currency', numeric: false, disablePadding: false, label: 'Currency' },
        { id: 'ordNo', numeric: false, disablePadding: false, label: 'Ord no' },
        { id: 'profileTextSize', numeric: false, disablePadding: false, label: 'Profile Text', renderValue: item => renderProfileText(item) },
        { id: 'avatarImageSize', numeric: false, disablePadding: false, label: 'Avatar image', renderValue: item => renderAvatarImageInfo(item) },
        { id: 'nPlayers', numeric: true, disablePadding: false, label: '#Players' },
        { id: 'nSyndicates', numeric: true, disablePadding: false, label: '#Syndicates' },
        { id: 'nActiveSyndicates', numeric: true, disablePadding: false, label: '#Actives' },
        { id: 'nDraftSyndicates', numeric: true, disablePadding: false, label: '#Drafts' },
        { id: 'nShares', numeric: true, disablePadding: false, label: '#Shares' },
        { id: 'maxShares', numeric: true, disablePadding: false, label: '#Total shares' }
      ];
      setColumns(cols);
    }
    const renderProfileText = (item : AgentOverviewItem) : string => {
      const maxSize = 10;
      if (item.profileText) {
        if (item.profileText.length > maxSize) {
          return `${item.profileText.length} ${item.profileText.substring(0, maxSize - 4)}...`;
        } else {
          return `${item.profileText.length} ${item.profileText}`;
        }
      } else if (item.profileText !== null && item.profileText !== undefined) {
        return "0";
      }
      return "";
    };
    const renderAvatarImageInfo = (item : AgentOverviewItem) : string => {
      const maxSize = 10;
      if (item.avatarImageSize) {
        return `${item.avatarImageSize} ${item.avatarImageType}`;
      } else if (item.avatarImageSize !== null && item.avatarImageSize !== undefined) {
        return "0";
      }
      return "";
    };
    const onSelect = (item : AgentOverviewItem) => {
        setSelectedId(item.id);
        if (props.onSelect){
          props.onSelect(item);
          return;
      }
    };
    const onDeselect = () => {
      setSelectedId(0);
      if (props.onDeselect){
        props.onDeselect();
        return;
      }
    };
  
  return (
    <DataTable columns={columns} items={props.items} getId={it => it.id} orderBy='id' order='asc' resultFilterEnabled={false} size='xsmall' onSelect={onSelect} onDeselect={onDeselect} />
    );
};
  
export default AgentOverviewTable;
