import React, { useEffect, useState } from "react";
import { Box, Button, MenuItem, TextField } from "@material-ui/core";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { bool } from "prop-types";
import { BaseItemState } from "../Ticker2/Ticker2Models";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import { act } from "react-dom/test-utils";
import RoundOverviewUtil from "../RoundOverview/RoundOverviewUtil";
import FixtureOverviewUtil from "./FixtureOverviewUtil";
import { ExtFixtureItem, ExtFixtureMapping, ExtFixtureParticipant, ActionState, MapFixtureRequest, ExtFixture } from "./FixtureOverviewModels";
import Ticker2Util from "../Ticker2/Ticker2Util";
import LeagueUtil from "../LeagueOverview/LeagueUtil";
import ExtFixtureTable from "./ExtFixtureTable";
import { Fixture } from "../TeamOverview/TeamOverviewModels";
import { blue } from "@material-ui/core/colors";
import { UpdateItemState } from "../CreateSyndicate/CreateSyndicateModels";
import MapFixtureDialog from "./MapFixtureDialog";
import { League } from "../LeagueOverview/LeagueModels";
import ConfirmationDialog from "../Components/ConfirmationDialog";

interface ExtFixtureViewState {
    fixtureId: number;
    providerId: string;
    valid: boolean;
    providerIdError: string;
}
interface ExtFixtureItemState extends BaseItemState<ExtFixtureItem, string> {
    leagueId: number;
}
interface ExtFixtureViewProps {
    fixture?: Fixture;
    league?: League;
}
interface MapFixtureState extends UpdateItemState<ExtFixtureItem> {
    fixture?: Fixture
}
const ExtFixtureView: React.FC<ExtFixtureViewProps> = (props) => {
    var propsLeagueId = 0;
    if (props.fixture) {
        propsLeagueId = props.fixture.leagueId;
    } else if (props.league) {
        propsLeagueId = props.league.id;
    }

    const [formState, setFormState] = useState<ExtFixtureViewState>({
        fixtureId: 0,
        providerId: "test",
        valid: true,
        providerIdError: "",
    });
    const [itemState, setItemState] = useState<ExtFixtureItemState>({
        leagueId: propsLeagueId,
        items: [],
        more: false,
        lastId: "",
        refreshing: false

    });
    const [selectedItem, setSelectedItem] = useState<ExtFixtureItem | undefined>(undefined);
    const [actionState, setActionState] = useState<ActionState>(FixtureOverviewUtil.createEmptyActionState());
    const [mapFixtureState, setMapFixtureState] = useState<MapFixtureState>({
        method: 'none',
        item: undefined,
        handled: false,
        updateNo: 0,
        version: 0,
        fixture: undefined
    });
    const history = useHistory();   

    useEffect(() => {
        refreshItems("Init");
    }, []);

    const refreshItems = (caller: string) => {
        if (itemState.refreshing) {
            return;
        }
        if (!formState.valid) {
            return;
        }
        var rs : ExtFixtureItemState = {
            leagueId: propsLeagueId,
            items: [],
            more: false,
            lastId: "",
            refreshing: false
        }
        var ast = FixtureOverviewUtil.createEmptyActionState();
        var lastId = caller === "onMore" ? itemState.lastId : "";
        if (lastId.length > 0) {
            rs.items = itemState.items;
        }
        // var pageSize = formState.limit ? formState.limit : props.criteria.pageSize;
        // var from : Date | undefined = formState.discardDateFilter ? undefined : props.criteria.from;
        // var to : Date | undefined = formState.discardDateFilter ? undefined : props.criteria.to;
        var success = false;
        itemState.refreshing = true;
        setActionState(FixtureOverviewUtil.createActionState(true, false, `RefreshItems(${caller})...`));
        FixtureOverviewUtil.getExtFixtureItems(formState.providerId, rs.leagueId).then( resp => {
            if (!resp.success) {
                ast.error = true;
                ast.message = `RefreshItems(${caller}) error: ${resp.message}`;
                return;
            }
            var listResponse = resp.item;
            if (listResponse) {
                if (listResponse.items) {
                    if (lastId.length > 0) {
                        for (const item of listResponse.items) {
                            rs.items.push(item);
                        }
                    } else {
                        rs.items = listResponse.items;
                    }
                    if (listResponse.items.length > 0) {
                        rs.lastId = listResponse.items[listResponse.items.length - 1].id;
                    }
                    // if (listResponse.pageSize <= listResponse.items.length) {
                    //     rs.more = true;
                    // }
                }           
            }
            success = true;
        }).catch( error => {
            ast.error = true;
            ast.message = CreateRoundUtil.getExceptionMessage(error, false, `RefreshItems(${caller}) error`);
        }).finally( () => {
            if (success || lastId.length <= 0) {
                setItemState(rs);
            }
            itemState.refreshing = false;
            setActionState(ast);
        });
    };
    const onRefreshItems = () => {
        refreshItems("Refresh");
    };
    const onMore = () : void => {
        if (itemState.lastId.length <= 0) {
            setActionState(FixtureOverviewUtil.createActionState(false, true, "More failure: LastId was not set."));
            return;
        }
        refreshItems("onMore");
    }
    const unmapFixture = (item: ExtFixtureItem) => {
        if (item.mappingId === undefined || item.mappingId <= 0) {
            setActionState(FixtureOverviewUtil.createActionState(true, false, `Unmap fixture failure: Ext fixture '${item.name}' is not mapped.`));
            return;
        }
        var ast = FixtureOverviewUtil.createEmptyActionState(); 
        setActionState(FixtureOverviewUtil.createActionState(true, false, "Unmapping fixture..."));
        var finished = false;
        FixtureOverviewUtil.unmapFixture(item.mappingId).then((resp) => {
            if (!resp.success) {
                ast.error = true;
                ast.message = `Unmap Fixture error: ${resp.message}`;
                return;
            }
            var extMapping : ExtFixtureMapping = {...item.extFixture.mapping, id: 0 };
            extMapping.status = "UNMAPPED";
            var extFixture = {... item.extFixture, mapping: extMapping};
            var extFixtureItem = FixtureOverviewUtil.toExtFixtureItem(extFixture);
            var extFixtureIndex = itemState.items.findIndex(it => it.id === item.id);
            if (extFixtureIndex >= 0) {
                itemState.items[extFixtureIndex] = extFixtureItem;
                setSelectedItem(extFixtureItem);
            }
        }).catch(error => {
            ast.error = true;
            ast.message = CreateRoundUtil.getExceptionMessage(error, false, "Unmap Fixture error");
        }).finally(() => {
            setActionState(ast);
        });
    };

    const onMap = () => {
        if (!props.fixture) {
            setActionState(FixtureOverviewUtil.createActionState(false, true, "Fixture is not selected."));
            return;
        }
        if (!selectedItem) {
            setActionState(FixtureOverviewUtil.createActionState(false, true, "Ext fixture is not selected."));
            return;
        }
        if (selectedItem.mappingId && selectedItem.mappingId > 0) {
            setActionState(FixtureOverviewUtil.createActionState(false, true, "Ext fixture is already mapped."));
            return;
        }
        setMapFixtureState({
            method: 'add',
            item: selectedItem,
            handled: false,
            updateNo: 0,
            version: 0,
            fixture: props.fixture
        });
    };
    const onMapItem = (item: ExtFixtureItem) => {
        if (!item) {
            setActionState(FixtureOverviewUtil.createActionState(false, true, "Ext fixture is not selected."));
            return;
        }
        if (!item.fixtureId) {
            setActionState(FixtureOverviewUtil.createActionState(false, true, "Ext fixture is not mapped to fixture."));
            return;
        }
        var mapping = item.extFixture?.mapping;
        if (!mapping) {
            setActionState(FixtureOverviewUtil.createActionState(false, true, "Ext fixture is not mapped to fixture."));
            return;

        }
        // if (item.mappingId && item.mappingId > 0) {
        //     setAction(FixtureOverviewUtil.createActionState(false, true, "Ext fixture is already mapped."));
        //     return;
        // }
        setMapFixtureState({
            method: (item.mappingId && item.mappingId > 0) ? 'remove' : 'add',
            item: item,
            handled: false,
            updateNo: 0,
            version: 0,
        });

    };
    const checkState = (fs: ExtFixtureViewState) => {
        var valid = true;
        var providerIdErr = "";
        if (!fs.providerId || fs.providerId.length <= 0) {
         valid = false;
         providerIdErr = "Required";
        }
        fs.valid = valid;
        fs.providerIdError = providerIdErr;
     };
     const onChangeProviderId = (providerId: string) => {
        if (providerId === formState.providerId) {
            return;
        }
        var fs = {...formState, providerId: providerId};
        var ist : ExtFixtureItemState = {
            leagueId: propsLeagueId,
            items: [],
            more: false,
            lastId: "",
            refreshing: false
        }
        checkState(fs);
        setFormState(fs);
        setItemState(ist);
        setSelectedItem(undefined);
    };
    const onSelect = (item : ExtFixtureItem) => {
        setSelectedItem(item);
    };
    const onMapFixtureClose = (extFixture: ExtFixture | undefined) : void => {
        var ms = {...mapFixtureState, handled: true};
        setMapFixtureState(ms);
        if (!extFixture) {
            return;
        }
        var extFixtureItem = FixtureOverviewUtil.toExtFixtureItem(extFixture);
        var extFixtureIndex = itemState.items.findIndex(it => it.id === extFixture.id);
        if (extFixtureIndex >= 0) {
            itemState.items[extFixtureIndex] = extFixtureItem;
            if (selectedItem && selectedItem.id === extFixture.id) {
                setSelectedItem(extFixtureItem);
            }
        } else {
            if (selectedItem) {
                setSelectedItem(undefined);
            }
        }
      
    };
    const onUnmapFixtureClose = (confirmed: boolean | undefined) : void => {
        if (mapFixtureState.handled) {
            return;
        }
        var ms = {...mapFixtureState, handled: true};
        setMapFixtureState(ms);
        if (!confirmed || !ms.item) {
            return;
        }
        unmapFixture(ms.item);      
    };
    
    
    if (itemState.leagueId !== propsLeagueId){
        itemState.leagueId = propsLeagueId;
        itemState.items = [];
        refreshItems("ChangeLeague");
    }
 
    var isExecuting = itemState.refreshing || actionState.executing;
    // var leagueInfo = "League - Not selected";
    // if (props.fixture) {
    //     leagueInfo = `League - Id:${props.fixture.leagueId} | Name: ${props.fixture.leagueName}`;
    // } else if (props.league) {
    //     leagueInfo = `League - Id:${props.league.id} | Name: ${props.league.name}`;
    // }
    // var fixtureInfo = "Fixture - Not selected";
    // if (props.fixture) {
    //     fixtureInfo = `Fixture - Id:${props.fixture.id} | Name: ${props.fixture.name} | Starting at: ${CreateRoundUtil.toGameDateString(props.fixture.startingAt)} | Status: ${props.fixture.status}`;
    // }
    var extFixtureInfo = "Ext fixture - Not selected";
    if (selectedItem) {
        extFixtureInfo = `Ext fixture - Id:${selectedItem.id} | Name: ${selectedItem.name} | Starting at: ${CreateRoundUtil.toGameDateString(selectedItem.startingAt)} | Mapping Id: ${selectedItem.mappingId ? selectedItem.mappingId.toString() : ""}`;
    }

    var actionBox = null;
    if ((actionState.executing || actionState.error) && actionState.message.length > 0) {
        actionBox = (
            <Box color={actionState.error ? RoundOverviewUtil.INVALID_COLOR : blue[500]} fontSize={12}>
                {actionState.message}
            </Box>
        );
    }

    var info = Ticker2Util.toItemStateString(itemState);
    var moreBtn = null;
    if (itemState.more && !isExecuting) {
        moreBtn = (<Button 
            variant="text"
            color="secondary"
            size="small"
            disabled={isExecuting}
            onClick={e => onMore()}>
            More
        </Button>);
    }
    var mapVisible = props.fixture ? true : false;
    var mapEnabled = (props.fixture && selectedItem && (!selectedItem.mappingId || selectedItem.mappingId === 0));
    var providerIds = LeagueUtil.getProviderIds();

    var mapDialog = null;
    if (mapFixtureState.item && !mapFixtureState.handled) {
        if (mapFixtureState.method === "add") {
            mapDialog = (
                <MapFixtureDialog open={true} fixture={mapFixtureState.fixture} extFixture={mapFixtureState.item.extFixture} timestamp={RoundOverviewUtil.getTimestamp()} onClose={onMapFixtureClose} providerId={formState.providerId} />
            );
        } else if (mapFixtureState.method === "remove") {
            var mapItem = mapFixtureState.item;
            const unmapContent = (
                <Box>
                    Please confirm that you want to unmap fixture <br/>'<b>{mapItem.fixtureName} ({CreateRoundUtil.toGameDateString(mapItem.fixtureStartingAt)})</b>' <br/>with external<br/>
                    '<b>{mapItem.name} ({CreateRoundUtil.toGameDateString(mapItem.startingAt)})</b>'<br/>at provider<br/><b>{formState.providerId}</b><br/>with mapping id<br/><b>{mapItem.mappingId}</b> .
                </Box>
            );
            mapDialog = (
                <ConfirmationDialog open={true} title="Unmap fixture" message="Please confirm that you want to unmap fixture." messageElement={unmapContent} onClose={onUnmapFixtureClose} />
            );            
        }

    }

    return (
        <div>
            {/* <Box fontSize={10}>
                {leagueInfo}
            </Box>
            <Box fontSize={10}>
                {fixtureInfo}
            </Box> */}
            <Box fontSize={10} marginTop={2}>
                <TextField
                    label="Provider"
                    select
                    name="providerId"
                    value={formState.providerId}
                    onChange={e => onChangeProviderId(e.target.value)}
                    style={{width:400, marginRight:"5px"}}
                    error={formState.providerIdError.length > 0}
                    helperText={formState.providerIdError}
                >
                    {providerIds.map(providerId => (
                    <MenuItem key={`providerId_${providerId}`} value={providerId}>
                        {providerId}
                    </MenuItem>
                    ))}
                </TextField>
                <Button 
                    variant="contained"
                    color="secondary"
                    size="small"
                    disabled={isExecuting}
                    style={{marginRight: "5px"}}
                    onClick={e => onRefreshItems()}>
                    Search
                </Button>
            </Box>
            <Box fontSize={10} marginTop={2}>
                {extFixtureInfo}
            </Box>
            {mapVisible ? (
            <Box>
                <Button 
                    variant="contained"
                    color="secondary"
                    size="small"
                    disabled={isExecuting || !mapEnabled}
                    style={{marginRight: "5px"}}
                    onClick={e => onMap()}>
                    Map
                </Button>
            </Box>
            ) : null}
            {actionBox}
            <ExtFixtureTable items={itemState.items} onSelect={onSelect} onMap={onMapItem} />
            {/* <Box>
                FormState:<br></br>
                <pre>{JSON.stringify(formState, undefined, 2)}</pre>
            </Box>
            <Box>
                MapFixtureState:<br></br>
                <pre>{JSON.stringify(mapFixtureState, undefined, 2)}</pre>
            </Box> */}
            {mapDialog}
        </div>
    );
};

export default ExtFixtureView;

